import React from 'react';
import { Dialog } from 'primereact/dialog';
import { ReactComponent as Close } from 'assets/images/hiringmanager/closeicon.svg';
// import { ReactComponent as DeclinePerson } from 'assets/images/hiringmanager/declineperson.svg';
import PrimaryButton from 'components/Button/primaryButton';
import BaseButton from 'components/Button/baseButton';

import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

function Decline({ isDecline, setDecline, quoteData, mutation }) {
  const { t } = useTranslation(['quotation', 'common']);
  const currentLanguageCode = cookies.get('i18next');
  const header = (
    <div
      className='flex justify-end cursor-pointer'
      onClick={() => setDecline(false)}
    >
      <Close />
    </div>
  );
  return (
    <div>
      <Dialog
        header={header}
        visible={isDecline}
        onHide={() => setDecline(false)}
        style={{
          // marginTop: '4rem',
          borderRadius: '1rem',
        }}
        className='w-10/12 max-w-md   h-60   rounded-lg'
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        closable={false}
        position='center'
        resizable={false}
      >
        <div className='flex flex-col justify-center items-center'>
          {/* <div>
            <DeclinePerson className='w-28 h-28' />
          </div> */}
          <div className='w-56 text-center pt-3'>
            {t('quotation:are_you_sure_you_want_to_delete_the_quotation')}
          </div>
          <div className='items-center pt-5'>
            <button
              className={`w-20 ${
                currentLanguageCode === 'ar' ? 'ml-5' : 'mr-5'
              }`}
              onClick={() => {
                mutation.mutate({
                  id: quoteData.id,
                  is_deleted: true,
                });
              }}
            >
              <PrimaryButton>
                <p className='text-sm leading-4'>{t('common:delete')}</p>
              </PrimaryButton>
            </button>
            <button
              className={`w-20 ${
                currentLanguageCode === 'ar' ? 'ml-5' : 'mr-5'
              }`}
              onClick={() => {
                setDecline(false);
              }}
            >
              <BaseButton>
                <p className='text-sm leading-3'>{t('common:cancel')}</p>
              </BaseButton>
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default Decline;
