import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import SearchField from './SearchField';

import ClientSection from './ClientSection';
import JobSection from './JobSection';
import ApplicantSection from './ApplicantSection';

import dayjs from 'dayjs';
var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

function DashboardOverview() {
  const location = useLocation();
  const history = useHistory();

  const today = new Date();
  const priorDate = new Date().setDate(today.getDate() - 30);

  const queryStringValues = queryString.parse(location.search);
  // console.log(queryStringValues);
  const [jobStartDate, SetJobStartDate] = useState(dayjs(priorDate).format());
  const [jobEndDate, SetJobEndDate] = useState(dayjs().format());
  const [applicantStartDate, SetApplicantStartDate] = useState(
    dayjs(priorDate).format()
  );
  const [applicantEndDate, SetApplicantEndDate] = useState(dayjs().format());

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: `?${
        jobStartDate || jobEndDate || applicantStartDate || applicantEndDate
          ? `jobfrom=${jobStartDate}&jobto=${jobEndDate}&applicantfrom=${applicantStartDate}&applicantto=${applicantEndDate}`
          : `jobfrom=&jobto=&applicantfrom=&applicantto=`
      }`,
    });
    // eslint-disable-next-line
  }, [jobStartDate, jobEndDate, applicantStartDate, applicantEndDate]);

  const getClientAnalytics = useQuery(['getClientAnalytics'], async () => {
    const resData = await makeAPIRequest.get(
      `${apiEndPoints.SuperAdminDashboardTotalClients}`
    );
    return resData?.data;
  });

  // const getJobsAppCount = useQuery(['getJobsAppCount'], async () => {
  //   const resData = await makeAPIRequest.get(
  //     `${apiEndPoints.AdminDashJobsAppsCountAPI}`
  //   );
  //   return resData?.data;
  // });

  const getJobMetrics = useQuery(
    ['getJobMetrics', jobStartDate, jobEndDate],
    async () => {
      // console.log(jobStartDate, dayjs(jobStartDate).format(), 'jobStartDate');
      // console.log(jobEndDate, dayjs(jobEndDate), 'jobEndDate');
      const resData = await makeAPIRequest.get(
        `${apiEndPoints.AdminDashJobMetricsAPI}?from_date=${dayjs(
          jobStartDate
        ).format('YYYY-MM-DD')}&to_date=${dayjs(jobEndDate).format(
          'YYYY-MM-DD'
        )}`
      );
      return resData?.data;
    }
    // {
    //   onSuccess: (data) => {
    //     SetJobStartDate(data.from_date);
    //     SetJobEndDate(+data.to_date);
    //   },
    // }
  );

  const getAppMetrics = useQuery(
    ['getAppMetrics', applicantStartDate, applicantEndDate],
    async () => {
      const resData = await makeAPIRequest.get(
        `${apiEndPoints.AdminDashAppMetricsAPI}?from_date=${dayjs(
          applicantStartDate
        ).format('YYYY-MM-DD')}&to_date=${dayjs(applicantEndDate).format(
          'YYYY-MM-DD'
        )}`
      );
      return resData?.data;
    }

    // {
    //   onSuccess: (data) => {
    //     SetApplicantMonthValue(data.month);
    //     SetApplicantYearValue(+data.year);
    //   },
    // }
  );

  return (
    <div className='flex flex-col w-full max-w-screen-xl mx-auto h-full px-4 lg:px-0'>
      <div className='flex w-full justify-end searchFunctionality mt-2 mb-6'>
        <SearchField
          history={history}
          queryStringValues={queryStringValues}
          location={location}
        />
      </div>
      <div className='flex flex-col lg:flex-row w-full mb-8'>
        <ClientSection
          getClientAnalytics={getClientAnalytics}
          history={history}
        />
      </div>
      <div className='flex flex-col lg:flex-row w-full mb-8'>
        <JobSection
          getJobMetrics={getJobMetrics}
          SetJobEndDate={SetJobEndDate}
          jobEndDate={jobEndDate}
          SetJobStartDate={SetJobStartDate}
          jobStartDate={jobStartDate}
          location={location}
          history={history}
        />
      </div>

      <div className='flex flex-col lg:flex-row w-full mb-8'>
        <ApplicantSection
          getAppMetrics={getAppMetrics}
          SetApplicantStartDate={SetApplicantStartDate}
          applicantStartDate={applicantStartDate}
          SetApplicantEndDate={SetApplicantEndDate}
          applicantEndDate={applicantEndDate}
          location={location}
          history={history}
        />
      </div>
    </div>
  );
}

export default DashboardOverview;
