import React from 'react';
// import Loader from 'components/Loader';
import loadable from '@loadable/component';
import { Skeleton } from 'primereact/skeleton';
import { useTranslation } from 'react-i18next';

import { ReactComponent as NoJobMetrics } from 'assets/images/dashboard/NoJobMetrics.svg';

const LineChartComponent = loadable(
  () => import('./charts/LineChartComponent'),
  {
    fallback: (
      <div className=' flex flex-1 flex-col'>
        <div className='flex flex-1 justify-center min-h-300px max-h-300px flex-col scroller overflow-y-auto'>
          <div className='flex my-4 justify-center'>
            <Skeleton height='12rem' width='12rem' borderRadius='12rem' />
          </div>
        </div>
      </div>
    ),
  }
);

function JobMetricsAnalytics({ getJobMetrics, isAppMetrics }) {
  const { t } = useTranslation(['dashboard']);
  return (
    <>
      <div className='w-full flex flex-col  px-0 py-0'>
        {getJobMetrics?.isLoading || getJobMetrics?.isFetching ? (
          <div className=' flex flex-1 flex-col'>
            <div className='flex flex-1 justify-center min-h-300px max-h-300px flex-col scroller overflow-y-auto'>
              <div className='flex my-4 justify-center'>
                <Skeleton height='12rem' width='12rem' borderRadius='12rem' />
              </div>
            </div>
          </div>
        ) : !!getJobMetrics?.data?.data.length ? (
          <div className=' flex flex-1 flex-col justify-between'>
            <LineChartComponent
              getJobMetrics={getJobMetrics}
              isAppMetrics={isAppMetrics}
            />
          </div>
        ) : (
          <div className=' flex justify-center items-center'>
            <div className='flex h-60 mt-6 justify-center items-center flex-col'>
              <NoJobMetrics />
              <span className='text-Neutrals-gray-1 text-sm mt-6 mb-2'>
                {isAppMetrics
                  ? t('dashboard:no_applicant_traffic_to_show_graph')
                  : t('dashboard:no_jobs_to_show_graph')}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default JobMetricsAnalytics;
