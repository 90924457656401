import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import LottiLoader from 'components/LottiLoader';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { ErrorToast, SuccessToast } from 'services/toasterService';
import { Dialog } from 'primereact/dialog';
// import Accept from './accept';
// import Decline from './decline';
import { ReactComponent as Close } from 'assets/images/hiringmanager/closeicon.svg';
import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as CheckDarkIcon } from 'assets/icons/CheckDarkIcon.svg';
import { ReactComponent as CheckLightIcon } from 'assets/icons/CheckLightIcon.svg';
import { ReactComponent as InfoIllusLight } from 'assets/icons/InfoIllusLight.svg';

function NewQuoteDialog({
  isVisible,
  setVisible,
  customerData,
  isReadOnly = false,
}) {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['quotation', 'common']);
  const header = (
    <div
      className='flex justify-between items-center cursor-pointer'
      onClick={() => setVisible(false)}
    >
      <div className='w-5 h-5'></div>
      <div>
        <h3 className='text-primary-rose text-xl font-semibold'>
          {t('quotation:client_details')}
        </h3>
      </div>
      <Close />
    </div>
  );
  const queryClient = useQueryClient();

  const updateQuotationMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.put(
        apiEndPoints.QUOTATION_REQUEST,
        data
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('newRequests');
        queryClient.invalidateQueries('InprogressRequests');
        queryClient.invalidateQueries('confirmedRequests');
        queryClient.invalidateQueries('DeletedRequests');
        setVisible(false);
        SuccessToast({ text: data.message });
      },
      onError: (err) => {
        ErrorToast({ text: err.response.data.message });
      },
    }
  );

  return (
    <>
      <div>
        <Dialog
          header={header}
          visible={isVisible}
          onHide={() => setVisible(false)}
          style={{
            // marginTop: '4rem',
            borderRadius: '1rem',
          }}
          className='w-10/12 max-w-4xl  h-80vh xl:80vh xl:h-90vh  rounded-lg'
          modal
          draggable={false}
          dismissableMask={true}
          blockScroll={true}
          closable={false}
          position='center'
          resizable={false}
        >
          <>
            {false ? (
              <div className='flex justify-center items-center w-full h-full'>
                <LottiLoader />
              </div>
            ) : (
              <>
                <div className='flex flex-col w-full text-Neutrals-gray-1 items-center px-6'>
                  <div className='w-full'>
                    <div className='flex w-full mt-4'>
                      <div className='flex flex-col w-1/2'>
                        <div className='flex my-1'>
                          <div className='w-44 text-neutrals-black'>
                            {t('common:company_name')}
                          </div>
                          <span className='mx-4 text-gray-2'>:</span>
                          <div className='flex-1'>
                            {customerData?.company_name
                              ? customerData?.company_name
                              : '-'}
                          </div>
                        </div>
                        <div className='flex my-1'>
                          <div className='w-44 text-neutrals-black'>
                            {t('common:contact')}
                          </div>
                          <span className='mx-4 text-gray-2'>:</span>
                          <div className='flex-1'>{customerData?.name}</div>
                        </div>
                      </div>
                      <div className='flex flex-col items-center w-1/2'>
                        <div className='flex flex-col'>
                          <p className='text-lg font-semibold text-primary-rose mb-1'>
                            {t('quotation:requested_on')}
                          </p>
                          <p className='my-1'>
                            {customerData?.created_at
                              ? dayjs(customerData?.created_at).format(
                                  'DD MMM YYYY, hh:mm A'
                                )
                              : '-'}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='flex my-1'>
                      <div className='w-44 text-neutrals-black'>
                        {t('common:telephone')}
                      </div>
                      <span className='mx-4 text-gray-2'>:</span>
                      <div className='flex-1'>
                        {customerData?.contact_number ? (
                          <span>
                            {customerData?.country_code}{' '}
                            {customerData?.contact_number}
                          </span>
                        ) : (
                          '-'
                        )}
                      </div>
                    </div>
                    <div className='flex my-1'>
                      <div className='w-44 text-neutrals-black'>
                        {t('common:email')}
                      </div>
                      <span className='mx-4 text-gray-2'>:</span>
                      <div className='flex-1'>{customerData?.email}</div>
                    </div>
                    <div className='flex my-1'>
                      <div className='w-44 text-neutrals-black'>
                        {t('common:cr_number')}
                      </div>
                      <span className='mx-4 text-gray-2'>:</span>
                      <div className='flex-1'>
                        {customerData?.commercial_rnumber
                          ? customerData?.commercial_rnumber
                          : '-'}
                      </div>
                    </div>
                    <div className='flex my-1'>
                      <div className='w-44 text-neutrals-black'>
                        {t('common:vat_number')}{' '}
                      </div>
                      <span className='mx-4 text-gray-2'>:</span>
                      <div className='flex-1'>
                        {customerData?.vat_number
                          ? customerData?.vat_number
                          : '-'}
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col w-full my-4'>
                    <div className='flex flex-col'>
                      <p className='text-lg font-semibold text-primary-rose mb-1'>
                        {t('common:address')}
                      </p>
                      <p className='my-1'>
                        {customerData?.address ? customerData?.address : '-'}
                      </p>
                    </div>
                  </div>

                  <div className='flex flex-col w-full my-4'>
                    <div className='flex flex-col'>
                      <p className='text-lg font-semibold text-primary-rose mb-1'>
                        {t('common:plan_details')}
                      </p>
                      <div>
                        <div className='flex flex-col w-full justify-center my-4'>
                          {customerData?.plan_info ? (
                            <div className='flex items-center justify-between border-b border-rose border-opacity-60 py-2'>
                              <p className='text-2xl font-semibold '>
                                {customerData?.plan_info.name}
                              </p>
                              <div className='flex items-center'>
                                <p
                                  className={`text-2xl font-semibold ${
                                    currentLanguageCode === 'ar'
                                      ? 'ml-2'
                                      : 'mr-2'
                                  }`}
                                >
                                  SR {customerData?.plan_info.amount ?? '-'}
                                </p>
                                <span className='text-848199'>
                                  /{' '}
                                  {
                                    <span className='text-848199 font-normal text-md '>
                                      {customerData?.plan_info.validity?.split(
                                        '.'
                                      )[0] / 86400 ?? '-'}{' '}
                                      {t('common:days')}
                                    </span>
                                  }
                                  , {t('common:billed_annually')}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className='flex items-center justify-between border-b border-rose border-opacity-60 py-2'>
                              <p className='text-2xl font-semibold '>
                                {t('common:custom_plan')}
                              </p>
                            </div>
                          )}
                          <div className='flex flex-col mt-6'>
                            <div className='flex justify-between my-2'>
                              <p className=''>{t('quotation:job_listings')}</p>
                              <p className='text-primary-rose font-semibold'>
                                {customerData?.plan_info
                                  ? customerData?.plan_info.job_postings
                                  : customerData?.job_listings}{' '}
                                {t('quotation:jobs')}
                              </p>
                            </div>
                            <div className='flex justify-between my-2'>
                              <p className=''>
                                {t('quotation:job_applications')} (
                                {t('quotation:per_job')})
                              </p>
                              <p className='text-primary-rose font-semibold'>
                                {customerData?.plan_info
                                  ? customerData?.plan_info.applicants_count
                                  : customerData?.job_applications}{' '}
                                {t('quotation:applications')}
                              </p>
                            </div>
                            <div className='flex justify-between my-2'>
                              <p className=''>
                                {t('quotation:ai_psychometric_assessments')} (
                                {t('quotation:per_job')})
                              </p>
                              <p className='text-primary-rose font-semibold'>
                                {customerData?.plan_info
                                  ? customerData?.plan_info.psychometric_count
                                  : customerData?.psy_assessments}{' '}
                                {t('quotation:assessments')}
                              </p>
                            </div>
                            <div className='flex justify-between my-2'>
                              <p className=''>
                                {t('quotation:integrated_video_interviews')}
                              </p>
                              <p className='text-primary-rose font-semibold'>
                                {customerData?.plan_info
                                  ? customerData?.plan_info?.interview_count ===
                                    -1
                                    ? 'Unlimited'
                                    : customerData?.plan_info?.interview_count
                                  : customerData?.video_interviews === -1
                                  ? 'Unlimited'
                                  : customerData?.video_interviews ?? '-'}
                              </p>
                            </div>
                            <div className='flex justify-between my-2'>
                              <p className=''>{t('quotation:team_members')}</p>
                              <p className='text-primary-rose font-semibold'>
                                {customerData?.plan_info
                                  ? customerData?.plan_info.team_count
                                  : customerData?.team_members}{' '}
                                {t('quotation:accounts')}
                              </p>
                            </div>
                            {!customerData?.for_hr ? (
                              <div className='flex justify-between my-2'>
                                <p className=''>{t('common:clients')}</p>
                                <p className='text-primary-rose font-semibold'>
                                  {customerData?.plan_info
                                    ? customerData?.plan_info.clients_count
                                    : customerData?.clients}{' '}
                                  {t('quotation:accounts')}
                                </p>
                              </div>
                            ) : null}
                            {!customerData?.for_hr ? (
                              <div className='flex justify-between my-2'>
                                <p className=''>
                                  {t('quotation:client_team_members')}
                                </p>
                                <p className='text-primary-rose font-semibold'>
                                  {customerData?.plan_info
                                    ? customerData?.plan_info.clients_team_count
                                    : customerData?.clients_team}{' '}
                                  {t('quotation:accounts')}
                                </p>
                              </div>
                            ) : null}
                            <div className='flex justify-between items-center my-2'>
                              <p className=''>{t('quotation:support')}</p>
                              <p className='text-primary-rose font-semibold'>
                                {t('quotation:included')}
                              </p>
                            </div>
                            <div className='flex flex-col my-2'>
                              <div className='flex justify-between items-center my-2'>
                                <p className='text-9B9B9B'>
                                  {t('common:email')}
                                </p>
                                <span>
                                  <CheckDarkIcon />
                                </span>
                              </div>
                              <div className='flex justify-between items-center my-2'>
                                <p className='text-9B9B9B'>
                                  {t('quotation:chat')}
                                </p>
                                <span>
                                  <CheckLightIcon />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className='flex flex-col mt-6'>
                            <p className='font-bold'>
                              {t('quotation:additional_features')}
                            </p>
                            <div className='flex flex-col my-2'>
                              <div className='flex justify-between items-center my-2'>
                                <p className='text-9B9B9B'>
                                  {t('quotation:custom_career_site')}
                                </p>
                                <span>
                                  <CheckDarkIcon />
                                </span>
                              </div>
                              <div className='flex justify-between items-center my-2'>
                                <div className='flex items-center'>
                                  <p className='text-9B9B9B'>
                                    {t('quotation:job_board_integration')}
                                  </p>
                                  <span
                                    className={`${
                                      currentLanguageCode === 'ar'
                                        ? 'mr-2'
                                        : 'ml-2'
                                    }`}
                                  >
                                    <InfoIllusLight />
                                  </span>
                                </div>
                                <span>
                                  <CheckLightIcon />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col w-full my-4'>
                    <div className='flex flex-col'>
                      <p className='text-lg font-semibold text-primary-rose mb-1'>
                        {t('quotation:message')}
                      </p>
                      <p className='my-1'>
                        {customerData?.message ? customerData?.message : '-'}
                      </p>
                    </div>
                  </div>
                  {isReadOnly ? null : (
                    <div className='flex w-full justify-center my-8'>
                      <button
                        className={`w-28 bg-red-600 py-1.5 text-white rounded ${
                          currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                        }`}
                        onClick={() =>
                          updateQuotationMutation.mutate({
                            id: customerData.id,
                            status: 'Declined',
                          })
                        }
                      >
                        {t('quotation:decline')}
                      </button>
                      <button
                        className={` w-28 bg-green-600 py-1.5 text-white rounded flex justify-center ${
                          currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                        }`}
                        onClick={() =>
                          updateQuotationMutation.mutate({
                            id: customerData.id,
                            status: 'Confirmed',
                          })
                        }
                      >
                        {t('quotation:accept')}
                        {/* {updateDemoRequest.isLoading ? <Loader /> : 'Accept'} */}
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        </Dialog>
      </div>
    </>
  );
}

export default NewQuoteDialog;
