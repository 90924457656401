import { Menu, Transition } from '@headlessui/react';
import { Fragment, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'contexts/authContext';

import Avatar from '../Avatar';

import { ReactComponent as Pulldown } from 'assets/icons/pullDownProfile.svg';

export default function UserDropDown({ handelLogoutModal }) {
  const { t } = useTranslation(['common']);
  const currentLanguageCode = cookies.get('i18next');
  const { authState } = useContext(AuthContext);

  const history = useHistory();
  const location = useLocation();

  return (
    <div className='text-right mt-1'>
      <Menu as='div' className='relative inline-block text-left'>
        <div className='relative'>
          <Menu.Button className='flex items-center'>
            <Avatar
              imgSrc={authState?.profilePicture}
              className='w-10 h-10 object-fill mx-1'
              userInitials={authState.User_initials}
            />
            <Pulldown />
          </Menu.Button>
          {location.pathname.includes('/profile') ? (
            <div className='absolute w-full h-0.5 rounded-lg bg-purple -bottom-3'></div>
          ) : null}
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items
            className={`absolute header-dd mt-4 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
              currentLanguageCode === 'ar' ? 'left-0' : 'right-0'
            }`}
          >
            <div className='px-2 py-1 '>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`group flex rounded-md items-center w-full px-2 py-2 text-base mb-1 opacity-50 cursor-default`}
                    onClick={() => history.push('/profile/myprofile')}
                    disabled
                  >
                    <UserProfileIcon
                      className={`w-5 h-5 ${
                        currentLanguageCode === 'ar' ? 'ml-3' : 'mr-3'
                      }`}
                      aria-hidden='true'
                    />
                    {t('common:profile')}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`group flex rounded-md items-center w-full px-2 py-2 text-base mb-1`}
                    onClick={() => handelLogoutModal(true)}
                  >
                    <LogoutIcon
                      className={`w-5 h-5 ${
                        currentLanguageCode === 'ar' ? 'ml-3' : 'mr-3'
                      }`}
                      aria-hidden='true'
                    />
                    {t('common:logout')}
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

function UserProfileIcon(props) {
  return (
    <svg
      {...props}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='https://www.w3.org/2000/svg'
    >
      <path
        d='M16.8976 13.7459C18.0748 12.8197 18.9341 11.5495 19.3559 10.1122C19.7777 8.67492 19.741 7.14189 19.2509 5.72641C18.7608 4.31094 17.8417 3.0834 16.6215 2.2146C15.4013 1.34579 13.9406 0.878906 12.4427 0.878906C10.9448 0.878906 9.48412 1.34579 8.26391 2.2146C7.04369 3.0834 6.12462 4.31094 5.63454 5.72641C5.14446 7.14189 5.10776 8.67492 5.52953 10.1122C5.9513 11.5495 6.81058 12.8197 7.98781 13.7459C5.9706 14.554 4.2105 15.8945 2.89518 17.6243C1.57985 19.3541 0.758593 21.4084 0.518962 23.5682C0.501616 23.7259 0.515499 23.8855 0.559819 24.0378C0.604138 24.1901 0.678026 24.3322 0.777263 24.456C0.977682 24.706 1.26919 24.8661 1.58766 24.9011C1.90612 24.9361 2.22546 24.8432 2.47542 24.6428C2.72538 24.4424 2.88549 24.1509 2.92052 23.8324C3.18419 21.4851 4.30344 19.3173 6.06443 17.743C7.82541 16.1688 10.1047 15.2985 12.4667 15.2985C14.8288 15.2985 17.108 16.1688 18.869 17.743C20.63 19.3173 21.7493 21.4851 22.0129 23.8324C22.0456 24.1275 22.1864 24.4 22.4081 24.5973C22.6299 24.7947 22.9169 24.9029 23.2137 24.9011H23.3458C23.6606 24.8649 23.9483 24.7057 24.1462 24.4583C24.3441 24.2109 24.4362 23.8953 24.4025 23.5802C24.1617 21.4143 23.336 19.3547 22.0139 17.6222C20.6919 15.8898 18.9232 14.5498 16.8976 13.7459ZM12.4427 12.8933C11.4927 12.8933 10.5641 12.6116 9.77424 12.0838C8.98437 11.5561 8.36874 10.8059 8.0052 9.92826C7.64167 9.05061 7.54655 8.08486 7.73188 7.15314C7.91721 6.22143 8.37466 5.3656 9.04639 4.69387C9.71812 4.02214 10.574 3.56469 11.5057 3.37936C12.4374 3.19403 13.4031 3.28914 14.2808 3.65268C15.1584 4.01622 15.9086 4.63185 16.4364 5.42172C16.9641 6.21158 17.2458 7.14022 17.2458 8.09019C17.2458 9.36405 16.7398 10.5857 15.839 11.4865C14.9383 12.3873 13.7166 12.8933 12.4427 12.8933Z'
        fill='#6600C9'
      />
    </svg>
  );
}

function LogoutIcon(props) {
  return (
    <svg
      {...props}
      viewBox='0 0 26 27'
      fill='none'
      xmlns='https://www.w3.org/2000/svg'
    >
      <path
        d='M14.4444 0.184082H11.5556V14.6285H14.4444V0.184082ZM21.4211 3.31853L19.37 5.36964C21.6522 7.20408 23.1111 10.0207 23.1111 13.1841C23.1111 18.7741 18.59 23.2952 13 23.2952C7.41 23.2952 2.88889 18.7741 2.88889 13.1841C2.88889 10.0207 4.34778 7.20408 6.61556 5.35519L4.57889 3.31853C1.77667 5.70186 0 9.2263 0 13.1841C0 20.363 5.82111 26.1841 13 26.1841C20.1789 26.1841 26 20.363 26 13.1841C26 9.2263 24.2233 5.70186 21.4211 3.31853Z'
        fill='#6600C9'
      />
    </svg>
  );
}
