import React, { Suspense } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { lazy } from '@loadable/component';

import LottiLoader from 'components/LottiLoader';
import NoPageFound from 'pages/common/error/noPageFound';

import './assessmentBank.styles.scss';

import BasePrivateLayout from '../../basePrivateLayout';
const SkillAssessment = lazy(() => import('./skillAssessment/index'));
const PsychometricAssessment = lazy(() =>
  import('./psychometricAssessment/index')
);

function AssessmentBank() {
  const { path } = useRouteMatch();

  return (
    <BasePrivateLayout>
      <Suspense
        fallback={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='max-w-main-page h-70vh flex justify-center items-center '>
              <LottiLoader />
            </div>
          </div>
        }
      >
        <Switch>
          <Redirect exact from={path} to={`${path}/skill-assessment`} />
          <Route path={`${path}/skill-assessment`}>
            <SkillAssessment />
          </Route>
          <Route
            path={`${path}/psychometric-assessment`}
            component={PsychometricAssessment}
          />

          {/* DO NOT CHNAGE this empty route position Add everything above this line */}
          <Route component={NoPageFound} />
        </Switch>
      </Suspense>
    </BasePrivateLayout>
  );
}

export default AssessmentBank;
