import React, { Fragment, useState } from 'react';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
// import { ReactComponent as SuccessIcon } from 'assets/icons/SuccessIcon.svg';
import { ReactComponent as NoClient } from 'assets/images/hiringmanager/noclients.svg';
import dayjs from 'dayjs';
import PrimaryButton from 'components/Button/primaryButton';
import LottiLoader from 'components/LottiLoader';
import { useQuery } from 'react-query';
import SendQuotationFormDialog from '../components/SendQuotationFormDialog';
import BaseButton from 'components/Button/baseButton';
import NewQuoteDialog from '../components/NewQuoteDialog';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import Tip from 'components/Tooltip';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

export default function ConfirmedRequests() {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['common', 'quotation']);
  const [popupOpen, setPopupOpen] = useState(false);
  const [sendQuotePopupOpen, setSendQuotePopupOpen] = useState(false);
  const [customerData, setCustomerData] = useState();
  const location = useLocation();
  const queryStringValues = queryString.parse(location.search);

  const { path } = useRouteMatch();
  const history = useHistory();

  const confirmedRequests = useQuery(
    ['confirmedRequests', queryStringValues.search, queryStringValues?.tab],
    async () => {
      const resData = await makeAPIRequest.get(
        `${apiEndPoints.QUOTATION_REQUEST}?status=Confirmed${
          queryStringValues?.tab === 'hm'
            ? '&for_hr=True'
            : queryStringValues?.tab === 'rec'
            ? '&for_hr=False'
            : ''
        }${
          queryStringValues.search ? '&search=' + queryStringValues.search : ''
        }`
      );
      return resData?.data;
    },
    {
      onSuccess: (data) => {},
    }
  );

  return (
    <div className='border mt-6 mx-2 rounded-xl shadow-lg bg-white'>
      <div className='flex mt-4'>
        <span
          className={`flex items-end pb-2 cursor-pointer mx-4 ${
            queryStringValues?.tab === 'all'
              ? 'text-neutrals-black border-b-2 border-gradient font-semibold'
              : 'text-9B9B9B'
          }`}
          onClick={async () => {
            history.push({
              pathname: `${path}`,
              search: 'tab=all',
            });
          }}
        >
          {t('common:all')}
        </span>
        <span
          className={`flex items-end pb-2 cursor-pointer mx-4 ${
            queryStringValues?.tab === 'hm'
              ? 'text-neutrals-black border-b-2 border-gradient font-semibold'
              : 'text-9B9B9B'
          }`}
          onClick={async () => {
            history.push({
              pathname: `${path}`,
              search: 'tab=hm',
            });
          }}
        >
          {t('common:hiring_manager')}
        </span>
        <span
          className={`flex items-end pb-2 cursor-pointer mx-4 ${
            queryStringValues?.tab === 'rec'
              ? 'text-neutrals-black border-b-2 border-gradient font-semibold'
              : 'text-9B9B9B'
          }`}
          onClick={async () => {
            history.push({
              pathname: `${path}`,
              search: 'tab=rec',
            });
          }}
        >
          {t('common:recruiter')}
        </span>
      </div>
      {confirmedRequests.isLoading ? (
        <div className='md:px-5 lg:px-0 '>
          <div className='rounded-lg w-full h-70vh bg-white p-8'>
            <div className='flex justify-center items-center w-full h-full'>
              <LottiLoader />
            </div>
          </div>
        </div>
      ) : (
        <>
          {' '}
          {confirmedRequests?.data?.data?.length > 0 ? (
            <>
              <div className='md:px-5 lg:px-0 '>
                <div className='rounded-lg w-full h-full bg-white p-8'>
                  <div
                    className={`flex w-full border-b-1 border-opacity-50 border-primary-rose text-base font-bold text-black pb-4 ${
                      currentLanguageCode === 'ar' ? 'pl-4' : 'pr-4'
                    }`}
                  >
                    <p className='flex flex-col w-1/6 text-center'>
                      {t('quotation:request_id')}
                    </p>
                    <p className='flex flex-col w-1/6 text-center'>
                      {t('common:date')}
                    </p>
                    <p className='flex flex-col w-1/6 text-center'>
                      {t('common:name')}
                    </p>
                    <p className='flex flex-col w-1/6 text-center'>
                      {t('common:company_name')}
                    </p>
                    <p className='flex flex-col w-1/6 text-center'>
                      {t('quotation:contact_details')}
                    </p>
                    <p className='flex flex-col w-1/6 items-center text-center'>
                      {t('quotation:action')}
                    </p>
                  </div>
                  <div
                    className={`flex flex-col w-full h-full max-h-60vh scroller overflow-y-scroll text-center ${
                      currentLanguageCode === 'ar' ? 'pl-2' : 'pr-2'
                    }`}
                  >
                    {confirmedRequests?.data?.data.map(
                      (newrequest, index, array) => (
                        <div
                          key={newrequest.id}
                          className={`flex w-full text-Neutrals-gray-1 xl:py-7  lg:py-4  md:py-4 ${
                            index + 1 === array.length
                              ? ''
                              : 'border-b-1 border-opacity-50 border-primary-rose'
                          }  `}
                        >
                          <p className='flex flex-col w-1/6 text-primary-rose'>
                            {newrequest?.id ? newrequest?.id : '-'}
                          </p>
                          <p className='flex flex-col w-1/6'>
                            {newrequest?.created_at
                              ? dayjs(newrequest?.created_at).format(
                                  'DD MMM YYYY'
                                )
                              : '-'}
                          </p>
                          <p className='flex flex-col w-1/6 text-black font-semibold truncate'>
                            {newrequest?.name ? newrequest?.name : '-'}
                          </p>
                          <p className='flex flex-col w-1/6'>
                            {newrequest?.company_name
                              ? newrequest?.company_name
                              : '-'}
                          </p>
                          <div className='flex flex-col w-1/6'>
                            <p className=''>
                              {newrequest?.contact_number ? (
                                <span>
                                  {newrequest?.country_code}{' '}
                                  {newrequest?.contact_number}
                                </span>
                              ) : (
                                '-'
                              )}
                            </p>
                            <Tip content={newrequest?.email}>
                              <p className='truncate px-4'>
                                {newrequest?.email ? newrequest?.email : '-'}
                              </p>
                            </Tip>
                          </div>
                          <div className='flex flex-col w-1/6 items-center justify-center'>
                            <div className='flex justify-center w-full'>
                              <button
                                className={`w-20 ${
                                  currentLanguageCode === 'ar' ? 'ml-3' : 'mr-3'
                                }`}
                                onClick={() => {
                                  setCustomerData(newrequest);
                                  setPopupOpen(true);
                                }}
                              >
                                <BaseButton>
                                  <p className='text-sm leading-4'>
                                    {t('common:view')}
                                  </p>
                                </BaseButton>
                              </button>
                              <button
                                className={`w-24 ${
                                  currentLanguageCode === 'ar' ? 'mr-3' : 'ml-3'
                                }`}
                                onClick={() => {
                                  setCustomerData(newrequest);
                                  setSendQuotePopupOpen(true);
                                }}
                              >
                                <PrimaryButton>
                                  <p className='text-sm leading-4'>
                                    {t('quotation:send_quote')}
                                  </p>
                                </PrimaryButton>
                              </button>
                              {/* <button
                                className='w-24 ml-1 cursor-default opacity-70'
                                disabled
                                onClick={() => {
                                  // setPopupOpen(true);
                                  setCustomerData(newrequest);
                                }}
                              >
                                <PrimaryButton>
                                  <p className='text-sm leading-4'>
                                    Send Quote
                                  </p>
                                </PrimaryButton>
                              </button> */}
                              {/* <button className='w-20' onClick={() => {}}>
                            <SuccessIcon />
                          </button> */}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <SendQuotationFormDialog
                isVisible={sendQuotePopupOpen}
                setVisible={setSendQuotePopupOpen}
                customerData={customerData}
              />
              <NewQuoteDialog
                isVisible={popupOpen}
                setVisible={setPopupOpen}
                customerData={customerData}
                isReadOnly={true}
              />
            </>
          ) : (
            <div className='flex h-60vh justify-center items-center text-center'>
              <div>
                <div
                  className={`${
                    currentLanguageCode === 'ar'
                      ? 'lg:mr-10 md:mr-12'
                      : 'lg:ml-10 md:ml-12'
                  }`}
                >
                  <NoClient />
                </div>
                <div className='text-Neutrals-gray-1   text-xl flex  pt-2 '>
                  {t('quotation:no_requests_to_show')}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
