import React, { useEffect } from 'react';
import AppRoutes from './routes';

import { QueryClient, QueryClientProvider } from 'react-query';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import withClearCache from './withClearCache';

import AuthProvider from 'contexts/authContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const CacheClearWithHOC = withClearCache(AppWithProviders);

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
];

function App() {
  return <CacheClearWithHOC />;
}

function AppWithProviders() {
  const currentLanguageCode = cookies.get('i18next') || 'ar';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr';
    localStorage.setItem('dir', JSON.stringify(currentLanguage.dir || 'ltr'));
  }, [currentLanguage, t]);

  return (
    <div className='App' id='App-root'>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <AppRoutes />
        </QueryClientProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
