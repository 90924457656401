import React from 'react';

import Loader from '../Loader';

const BaseButton = ({
  children,
  isLoading = false,
  classNames,
  blackVariant,
}) => {
  if (blackVariant) {
    if (isLoading) {
      return (
        <div className='text-purple border px-2 border-black leading-5 py-2 rounded-md flex items-center justify-center'>
          <Loader color='#6B6B6B' />
        </div>
      );
    }
    return (
      <div
        className={`${classNames}  text-black border border-black px-2 py-2 rounded-md leading-5 hover:bg-gray-50 transition-colors`}
      >
        {children}
      </div>
    );
  } else {
    if (isLoading) {
      return (
        <div className='text-purple border px-2 border-purple leading-5 py-2 rounded-md flex items-center justify-center'>
          <Loader color='#6600C9' />
        </div>
      );
    }
    return (
      <div
        className={`${classNames} text-purple border border-purple px-2 py-2 rounded-md leading-5 hover:bg-gray-50 transition-colors`}
      >
        {children}
      </div>
    );
  }
};

export default BaseButton;
