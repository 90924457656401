import React, { useState } from 'react';
import { useWindowSize } from 'react-use';
import { isMobile } from 'react-device-detect';

import AuthHeader from 'components/Header/authHeader';
import LogoutModal from 'components/LogoutModal';
// import NotificationPanel from 'components/notification';

import ComponentLevelErrorBoundary from 'pages/common/error/CompnentLevelErrorBoundary';

import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as LowIlls } from 'assets/images/profile/lowIlls.svg';
import { ReactComponent as MobileScreenIlls } from 'assets/images/profile/MobileScreenIlls.svg';

const BasePrivateLayout = ({ children }) => {
  const { width } = useWindowSize();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  // const [showNotificationModal, setShowNotificationModal] = useState(false);

  if (width < 750 || isMobile) {
    return (
      <div className='h-90vh relative'>
        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full'>
          <Logo className='w-full mx-auto mb-6' />
          <div className='relative bg-white shadow-xl h-60vh text-center w-10/12 mx-auto border rounded-md'>
            <MobileScreenIlls className='w-4/5 mt-16 mb-8 mx-auto' />
            <p className='text-tag-not-scheduled text-2xl pb-2 font-semibold'>
              We’re sorry!
            </p>
            <p className='text-sm text-141414 px-4'>
              Currently, this application is not mobile responsive yet.
            </p>
            <p className='text-sm text-141414 px-4 pt-1'>
              Please login using your laptop or a desktop.
            </p>
            <LowIlls className='absolute bottom-8 w-full mx-auto' />
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <AuthHeader handelLogoutModal={setIsLogoutModalOpen} />
      <ComponentLevelErrorBoundary>
        <div>{children}</div>
      </ComponentLevelErrorBoundary>
      <LogoutModal
        handelLogoutModal={setIsLogoutModalOpen}
        isLogoutModalOpen={isLogoutModalOpen}
      />
      {/* <NotificationPanel
        notificationVisible={showNotificationModal}
        setNotificationPanelVisible={setShowNotificationModal}
      /> */}
    </>
  );
};

export default BasePrivateLayout;
