import React from 'react';
// import Loader from 'components/Loader';
import loadable from '@loadable/component';
import { Skeleton } from 'primereact/skeleton';

import { useTranslation } from 'react-i18next';

import { ReactComponent as NoClientsIllus } from 'assets/images/dashboard/NoClientsIllus.svg';

const ExtraInfoDonutPieChart = loadable(
  () => import('./charts/ExtraInfoDonutPieChart'),
  {
    fallback: (
      <div className=' flex flex-1 flex-col'>
        <div className='flex flex-1 justify-center min-h-300px max-h-300px flex-col scroller overflow-y-auto'>
          <div className='flex my-4 justify-center'>
            <Skeleton height='12rem' width='12rem' borderRadius='12rem' />
          </div>
        </div>
      </div>
    ),
  }
);

function ClientAnalytics({ getClientAnalytics }) {
  const { t } = useTranslation(['dashboard']);
  return (
    <>
      <div className='w-full flex flex-col  px-6 py-0'>
        {getClientAnalytics.isLoading || getClientAnalytics.isFetching ? (
          <div className=' flex flex-1 flex-col'>
            <div className='flex flex-1 justify-center min-h-300px max-h-300px flex-col scroller overflow-y-auto'>
              <div className='flex my-4 justify-center'>
                <Skeleton height='12rem' width='12rem' borderRadius='12rem' />
              </div>
            </div>
          </div>
        ) : getClientAnalytics?.data?.data?.total_clients > 0 ? (
          <div className=' flex flex-1 flex-col justify-between'>
            <ExtraInfoDonutPieChart
              chartData={[
                {
                  name: 'Active Clients',
                  hm: getClientAnalytics?.data?.data?.active_hm,
                  rec: getClientAnalytics?.data?.data?.active_rec,
                  value:
                    getClientAnalytics?.data?.data?.total_active_clients || 0,
                  color: '#FB8861',
                  start: '#F272C1',
                  end: '#EB249E',
                },
                {
                  name: 'Inactive Clients',
                  hm: getClientAnalytics?.data?.data?.inactive_hm,
                  rec: getClientAnalytics?.data?.data?.inactive_rec,
                  value:
                    getClientAnalytics?.data?.data?.total_inactive_clients || 0,
                  color: '#DADADA',
                  start: '#DADADA',
                  end: '#AEAEAE',
                },
              ]}
              ClientData={{
                title: 'Total Clients',
                value: getClientAnalytics?.data?.data?.total_clients,
              }}
            />
          </div>
        ) : (
          <div className=' flex justify-center items-center'>
            <div className='flex h-80 mt-6 justify-center items-center flex-col'>
              <NoClientsIllus />
              <span className='text-Neutrals-gray-1 text-sm mt-6 mb-2'>
                {t('dashboard:no_clients_to_show')}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ClientAnalytics;
