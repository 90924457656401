import React from 'react';
import { Dialog } from 'primereact/dialog';
import { ReactComponent as Close } from 'assets/images/hiringmanager/closeicon.svg';
// import { ReactComponent as AcceptPerson } from 'assets/images/hiringmanager/acceptperson.svg';
import PrimaryButton from 'components/Button/primaryButton';
import BaseButton from 'components/Button/baseButton';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

function Accept({ isAccept, setAccept, quoteData, mutation }) {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['quotation', 'common']);
  const header = (
    <div
      className='flex justify-end cursor-pointer'
      onClick={() => setAccept(false)}
    >
      <Close />
    </div>
  );
  return (
    <div>
      <Dialog
        header={header}
        visible={isAccept}
        onHide={() => setAccept(false)}
        style={{
          // marginTop: '4rem',
          borderRadius: '1rem',
        }}
        className='w-10/12 max-w-md h-60 rounded-lg'
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        closable={false}
        position='center'
        resizable={false}
      >
        <div className='flex flex-col justify-center items-center'>
          {/* <div>
            <AcceptPerson className='w-28 h-28' />
          </div> */}
          <div className='w-56 text-center pt-3'>
            {t('quotation:are_you_sure_you_want_mark_the_quotation_as_paid')}
          </div>
          <div className='items-center pt-5'>
            <button
              className={`w-20 ${
                currentLanguageCode === 'ar' ? 'ml-5' : 'mr-5'
              }`}
              onClick={() => {
                mutation.mutate({
                  id: quoteData.id,
                });
              }}
            >
              <PrimaryButton>
                <p className='text-sm leading-4'>{t('quotation:yes')}</p>
              </PrimaryButton>
            </button>
            <button
              className={`w-20 ${
                currentLanguageCode === 'ar' ? 'ml-5' : 'mr-5'
              }`}
              onClick={() => {
                setAccept(false);
              }}
            >
              <BaseButton>
                <p className='text-sm leading-3'>{t('quotation:no')}</p>
              </BaseButton>
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default Accept;
