const URLS = {
  // Authentication urls

  REGISTER: '/Profile/UserProfile/',
  LOGIN: '/AptaAdmin/AdminLoginAPI/',
  CLIENTLOGIN: '/Profile/ClientLoginAPI/',
  LOGOUT: '/AptaAdmin/AdminLogoutAPI/',
  VERIFYOTP: '/Profile/VerifyOtp/',
  RESENDOTP: '/Profile/ResendOtp/',
  CHECKEXISTINGUSERDATA: '/Profile/VerifyExistence/',
  COUNTRYLIST: '/Profile/GetCountriesList/',
  INDUSTRYLIST: '/Profile/ClientIndustries/',
  COUNTRYCODE: '/Profile/GetCountryCodes/',
  CURRENCYLIST: '/Profile/GetCurrencies/',
  FORGOTPASSWORD: '/Profile/ForgetPassword/',
  CHECKUSERPERMISSION: '/Profile/CheckUserPermissions/',
  CLIENTCHECKUSERPERMISSION: '/Clients/ClientCheckUserPermissions/',

  // Profile urls

  CHNAGEPASSWORD: '/Profile/ChangePassword/',
  CLIENTCHNAGEPASSWORD: '/Clients/ClientChangePassword/',
  UPDATEPROFILEDATA: '/Profile/updateuserprofile/',
  UPDATECLIENTPROFILE: '/Clients/ClientProfile/',
  COMPANYDETAILS: '/Profile/Company_logo/',
  EMAILACCOUNTSETUP: '/Profile/EmailAccountsetup/',
  GETALLPLANS: '/Profile/Plans/',
  CURRENTPLANDETAILS: '/Profile/GetPlanInfo/',
  NOTIFICATIONS: '/Profile/NotificationsAPI/',
  RazorPayRouteAPI: '/Profile/RazorPayRouteAPI/',
  StripePaymentSessionAPI: '/Profile/StripePaymentSessionAPI/',
  CHANGEJOBSTAGELABELNAME: '/Profile/LabelNamesAPI/',
  CLIENTJOBSTAGELABELNAME: '/Clients/ClientLabelNamesAPI/',

  CREATE_MANUAL_PAYMENT: '/AptaAdmin/CreatePaymentAPI/',
  CLIENT_PLAN_INFO: '/AptaAdmin/ClientsAndPlansInfoAPI/',

  REFERRAL_AND_WALLET: '/AptaAdmin/AdminWalletAndReferralAPI/',
  REPORTS_ADMIN: '/AptaAdmin/AdminReportsAPI/',

  JOB_ANALYTICS_DATA: '/AptaAdmin/AdminJobAnalyticsAPI/',

  // Database api

  DATABASESETUPAPI: '/Profile/DatabaseSetup/',

  // Quotation requests
  QUOTATION_REQUEST: '/AptaAdmin/QuotationRequestsAPI/',
  SEND_QUOTATION_REQUEST: '/AptaAdmin/QuotationAPI/',
  QUOTATION_MARK_AS_PAID: '/AptaAdmin/MarkAsPaidAPI/',

  // JObs APIS

  JOBPOSTING: '/Jobs/JobPosting/',
  CLIENTJOBS: '/Clients/ClientJobPosting/',
  CLIENTJOBSTAGEASSIGN: '/Clients/ClientSideJobStagesAssign/',
  SPECIFICCLIENTJOBSTAGE: '/Clients/ClientJobStagesAPI/',

  JOBEVALUATION: '/Jobs/JobEvaluationAPI/',
  JOBLOCATIONS: '/Jobs/JobLocations/',
  INVITEAPPLICANTSFORAJOB: '/Jobs/InviteApplicants/',
  JOBAPPLICANTS: '/Jobs/JobApplicants/',
  COMPAREAPPLICANTS: '/Jobs/CompareApplicants/',
  COMPAREAPPLICANTSCLIENTS: '/Clients/ClientCompareApplicants/',
  SPECIFICJOBSTAGE: '/Jobs/GetJobStages/',
  JOBSTAGECOMPLETEDDAYS: '/Jobs/GetBufferDays/',
  JOBSHOWEMAILTEMPLATE: '/Jobs/ShowEmailTemplate/',

  VIDEOINTERVIEWEMAILTEMPLATE: '/Jobs/ShowVideoEmailTemplate/',
  CLIENTVIDEOINTERVIEWTWMPLATE:
    '/Clients/ClientShowVideoInterviewEmailTemplate/',
  SHOWREJECTTEMPLATE: '/Jobs/ShowRejectionTemplate/',
  JOBAPPLICANTSEARCH: '/Jobs/JobApplicantSearch/',
  JOBDESCRIPTIONPARSER: '/Jobs/JobParserAPI/',

  // Applicants APIS
  JOBAPPLICANTSNOTES: '/Jobs/ApplicantNotes/',
  CLIENTJOBAPPLICANTSNOTES: '/Clients/ClientApplicantNotesAPI/',
  CLIENTJOBAPPLICANTS: '/Clients/ClientJobApplicants/',
  APPLICANTCHANGESTATUS: '/Jobs/ChangeStatusApplicant/',
  CLIENTAPPLICANTCHANGESTATUS: '/Clients/ClientChangeStatusApplicant/',
  APPLICANTSCHEDULESTAGE: '/Jobs/ScheduleStage/',
  APPLICANTSMAILS: '/Jobs/SendMailToApplicant/',
  CLIENTAPPLICANTSMAILS: '/Clients/ClientSendMailToApplicantAPI/',
  APPLICANTSASSESSMENTINFO: '/Jobs/GetApplicantAssessmentInfo/',
  CLIENTAPPLICANTASSESSMENTINFO: '/Clients/ClientGetApplicantAssessmentInfo/',
  APPLICANTSSCHEDULEINTERVIEW: '/Jobs/ScheduleVideoStages/',
  CLIENTAPPLICANTSSCHEDULEINTERVIEW: '/Clients/ClientScheduleVideoStages/',
  APPLICANTSVIDEOINTERVIEWJITSI: '/Jobs/VideoInterview/',
  CLIENTAPPLICANTSVIDEOINTERVIEWJITSI: '/Clients/ClientVideoInterviewAPI/',
  APPLICANTSADDFEEDBACKAFTERINTERVIEW: '/Jobs/EndVideoInterview/',
  CLIENTAPPLICANTSADDFEEDBACKAFTERINTERVIEW:
    '/Clients/ClientEndVideoInterview/',
  APPLICANTSMOVETOCLIENT: '/Jobs/MoveToClientAPI/',
  JOBSAPPLICANTSCSV: '/Jobs/GenerateApplicantsCSV/',

  // Team urls

  TEAMMEMBER: '/team/Teammember/',
  CLIENTTEAMMEMBER: '/Clients/ClientTeamMember/',
  TEAMMEMBERFORMENTIONS: '/team/GetTeamMemberMention/',

  // Recruiter URL

  MOVEAPPLICANTSTOCLIENT: '/Jobs/MoveToClientAPI/',

  // Client urls

  CREATECLIENT: '/Profile/CreateClient/',
  CLIENTPENDINGJOBS: '/Clients/ClientPendingJobs/',
  CLIENTSTAGEASSIGN: '/Clients/ClientSideJobStagesAssign/',
  CLIENTVIDEOINTERVIEW: '/Clients/ClientScheduleVideoStages/',
  CLIENTJOBLOCATION: 'Clients/ClientJobLocation/',
  CLIENTAPPLICANTREJECTIONEMAIL: '/Clients/ClientShowRejectionTemplate/',

  // Assessments

  PSYCHOMETRYQUESTIONS: '/Assessment/PsychometryQuestionsAPI/',
  DISCASSESSMENT: '/Profile/DISCAssessmentQuestionsAPI/',
  BULKUPLOAD: '/Assessment/BulkUpload/',
  BULK_UPLOAD: '/Assessment/BulkUpload/',

  // Assessment Database URL'S
  DATABASE_DOMAINS: '/Profile/CreateDomain/',
  DATABASE_DOMAIN_FOLDERS: '/Profile/CreateFolder/',
  DATABASE_DOMAIN_FOLDER_QUESTIONS: '/Profile/CreateQuestion/',

  // Assessment urls

  CREATE_ASSESSMENT_FOLDER: '/Assessment/CreateAssessmentfolder/',
  CREATE_SKILL_ASSESSMENT_QUESTIONS: '/Assessment/QuestionsAPI/',

  SKILL_ASSESSMENT_REPORT: '/Assessment/SkillReportAPI/',

  // Assessment urls

  CREATEASSESSMENTFOLDER: '/Assessment/CreateAssessmentfolder/',
  CREATESKILLASSESSMENTQUESTIONS: '/Assessment/QuestionsAPI/',

  // Job application api
  JOBAPPLICATIONAPI: '/Jobs/JobApplicationAPI/',

  // Dashboard
  DashboardAnalytics: '/Dashboard/ActiveJobs/',
  DashboardClientActiveJobs: '/Clients/ClientActiveJobs/',
  DashboardClientPendingJobs: '/Clients/ClientDashBoard/',
  DashboardActivityTracker: '/Dashboard/ActivityTracker/',
  DashboardMeetingSchedule: '/Dashboard/MeetingSchedule/',
  DashboardClientMeetingSchedule: '/Clients/ClientMeetingSchedule/',
  DashboardRecruiterTotalClients: '/Dashboard/RecruiterTotalClients/',
  SuperAdminDashboardTotalClients: '/AptaAdmin/AdminDashClientCountAPI/',
  AdminDashJobsAppsCountAPI: '/AptaAdmin/AdminDashJobsAppsCountAPI/',
  AdminDashJobMetricsAPI: '/AptaAdmin/AdminDashJobMetricsAPI/',
  AdminDashAppMetricsAPI: '/AptaAdmin/AdminDashAppMetricsAPI/',
  AdminDashClientSearchAPI: '/AptaAdmin/AdminDashClientSearchAPI/',
  ClientPendingJobs: '/Clients/ClientPendingJobs/',
  HiringManagerAPI: '/AptaAdmin/RegisteredClientsAPI/',
  PlansNamesAPI: '/AptaAdmin/PlansNamesAPI/',
  RecruiterJobsDashAPI: '/AptaAdmin/RecruiterJobsDashAPI/',

  ADMIN_DEMO: '/Profile/ScheduleDemoAPI/',

  SKILL_TRANING_API_UPLOAD: '/AptaAdmin/UploadSkillWordsAPI/',
  SKILL_TRANING_API: '/AptaAdmin/TrainSkillWordsAPI/',
};

export default URLS;
