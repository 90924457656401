import PrimaryButton from 'components/Button/primaryButton';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { ReactComponent as NopageIlls } from 'assets/images/image_maintanace.svg';
import { ReactComponent as Logo } from 'assets/images/logo.svg';

const NoPageFound = () => {
  const { t } = useTranslation(['common']);
  const history = useHistory();
  return (
    <div className='relative max-h-80vh h-80vh max-w-main-page mx-auto'>
      <div className='flex justify-center mt-4'>
        <Logo className='w-40' />
      </div>
      <div className='bg-white shadow-lg mx-auto w-full mt-8 py-4 pt-8 border rounded h-auto absolute transform -translate-x-1/2 left-1/2 top-1/2 -translate-y-1/2'>
        <div className='w-4/12 mx-auto'>
          <NopageIlls className='w-72 mx-auto' />
        </div>
        <p className='text-3xl text-center my-3 mt-10'>{t('common:oh_no')}</p>
        <p className='text-lg text-center'>
          {t('common:sorry_we_could_not_find_that_page')}
        </p>
        <div className='flex justify-center my-6'>
          <button className='mx-auto w-32' onClick={() => history.goBack()}>
            <PrimaryButton>{t('common:back')}</PrimaryButton>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoPageFound;
