import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import UserDropDown from './HeaderUserDropdown';
// import { NotificationContext } from 'contexts/notificationContext';
// import { AuthContext } from 'contexts/authContext';

import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as Notification } from 'assets/icons/bell.svg';
// import { ReactComponent as Message } from 'assets/icons/message.svg';

const AuthHeader = ({
  handelLogoutModal,
  setShowNotificationModal,
  disabled,
}) => {
  const { t } = useTranslation(['common']);
  const currentLanguageCode = cookies.get('i18next');
  // const { notificationCount } = useContext(NotificationContext);

  // const { authState } = useContext(AuthContext);

  const location = useLocation();

  const removeToast = () => {
    toast.remove();
  };

  return (
    <div className='sticky top-0 z-50 w-full h-20 bg-white shadow-md flex items-center'>
      <div className='flex w-full justify-between px-4 2xl:px-1 py-2 max-w-main-page mx-auto items-center'>
        <div className='w-min lg:w-1/6'>
          <Logo className='w-32 h-20' />
        </div>

        <div className='lg:w-3/4 w-4/5 flex justify-center items-center text-xs sm:text-base lg:text-lg'>
          <ul className='flex justify-between items-center w-full mt-2 transition-all'>
            <li
              className={` cursor-pointer whitespace-nowrap ${
                location.pathname.includes('/dashboard')
                  ? 'border-b-3 border-gradient'
                  : ' text-gray-700'
              }`}
              onClick={removeToast}
            >
              <Link to='/dashboard' className='px-1 sm:px-3 pb-1'>
                {t('common:dashboard')}
              </Link>
            </li>

            <li
              className={`cursor-pointer ${
                location.pathname.includes('/manageClients')
                  ? 'border-b-3 border-gradient'
                  : 'text-gray-700'
              }`}
              onClick={removeToast}
            >
              <Link to='/manageClients' className='px-1 sm:px-3 pb-1'>
                {t('common:manage_clients')}
              </Link>
            </li>

            <li
              className={`cursor-pointer ${
                location.pathname.includes('/assessmentBank')
                  ? 'border-b-3 border-gradient'
                  : 'text-gray-700'
              }`}
              onClick={removeToast}
            >
              <Link to='/assessmentBank' className='px-1 sm:px-3 pb-1'>
                {t('common:assessments')}
              </Link>
            </li>
            <li
              className={`cursor-not-allowed opacity-60 ${
                location.pathname.includes('/skill-training')
                  ? 'border-b-3 border-gradient'
                  : 'text-gray-700'
              }`}
              onClick={removeToast}
            >
              {/* <Link to='/skill-training' className='px-1 sm:px-3 pb-1'> */}
              {t('common:skill_words')}
              {/* </Link> */}
            </li>
            <li
              className={` cursor-pointer ${
                location.pathname.includes('/demo')
                  ? 'border-b-3 border-gradient'
                  : 'text-gray-700'
              }`}
              onClick={removeToast}
            >
              {/* <span className='px-1 sm:px-3 pb-1'>Demo</span> */}
              <Link to='/demo' className='px-1 sm:px-3 pb-1'>
                {t('common:demo')}
              </Link>
            </li>
            <li
              className={` cursor-pointer ${
                location.pathname.includes('/quotation-requests')
                  ? 'border-b-3 border-gradient'
                  : 'text-gray-700'
              }`}
              onClick={removeToast}
            >
              {/* <span className='px-1 sm:px-3 pb-1'>Quotation requests</span> */}
              <Link
                to='/quotation-requests/new?tab=all'
                className='px-1 sm:px-3 pb-1'
              >
                {t('common:quotation_requests')}
              </Link>
            </li>
          </ul>
        </div>

        <div className='w-1/6 justify-end items-center hidden sm:flex mr-0'>
          <div className='flex justify-center items-center'>
            <>
              {/* <Message /> */}
              <Notification />
            </>

            <div
              className={`${currentLanguageCode === 'ar' ? 'mr-6' : 'ml-6'}`}
            >
              <UserDropDown handelLogoutModal={handelLogoutModal} disabled />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthHeader;
