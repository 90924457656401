import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import cookies from 'js-cookie';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'react-circular-progressbar/dist/styles.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';
import './index.scss';

import App from './App';
import reportWebVitals from './reportWebVitals';
import Loader from 'components/Loader';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    supportedLngs: ['ar', 'en'],
    fallbackLng: 'ar',
    debug: false,
    lng: cookies.get('i18next') || 'ar',
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
    },
  });

ReactDOM.render(
  <Suspense
    fallback={
      <div className='bg-white rounded-xl max-w-screen-xl w-full mx-auto min-h-80vh flex justify-center items-center'>
        <Loader width='70' height='70' color='#FF009C' />
      </div>
    }
  >
    <React.StrictMode>
      <>
        <Toaster
          position='top-right'
          reverseOrder={false}
          containerClassName='toast-cont'
          containerStyle={{
            position: 'fixed',
          }}
        />
        <App />
      </>
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();

reportWebVitals();
