import Tooltip from '@atlaskit/tooltip';

const Tip = ({ children, content, ...props }) => {
  return (
    <Tooltip content={content} {...props}>
      {children}
    </Tooltip>
  );
};

export default Tip;
