import React, { Suspense } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { lazy } from '@loadable/component';

import LottiLoader from 'components/LottiLoader';
import NoPageFound from 'pages/common/error/noPageFound';

import './manageClients.styles.scss';

import BasePrivateLayout from '../../basePrivateLayout';
const ClientsOverview = lazy(() => import('./Components/ClientsOverview'));
const SingleClient = lazy(()=>import('./Components/singleClient/index'))
// const HiringManager = lazy(() => import('./hiringmanager/index'));

function ManageClients() {
  const { path } = useRouteMatch();

  return (
    <BasePrivateLayout>
      <Suspense
        fallback={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='max-w-main-page h-70vh flex justify-center items-center '>
              <LottiLoader />
            </div>
          </div>
        }
      >
        <Switch>
          <Redirect exact from={path} to={`${path}/clientsoverview`} />
          <Route path={`${path}/clientsoverview`} component={ClientsOverview} />
          <Route path={`${path}/singleclient/:id`} component={SingleClient} />
          
          
          {/* DO NOT CHNAGE this empty route position Add everything above this line */}
          <Route component={NoPageFound} />
        </Switch>
      </Suspense>
    </BasePrivateLayout>
  );
}

export default ManageClients;
