import React from 'react';
// import queryString from 'query-string';
import { Calendar } from 'primereact/calendar';
import dayjs from 'dayjs';
import { ReactComponent as DatePick } from 'assets/images/dashboard/datepick.svg';
import JobMetricsAnalytics from './JobMetricsAnalytics';
import ApplicantAnalytics from './ApplicantAnalytics';
import 'react-datepicker/dist/react-datepicker.css';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

function ApplicantSection({
  getAppMetrics,
  applicantEndDate,
  SetApplicantEndDate,
  applicantStartDate,
  SetApplicantStartDate,
}) {
  const { t } = useTranslation(['dashboard', 'common']);
  const currentLanguageCode = cookies.get('i18next');
  const minDate = new Date(applicantStartDate);
  minDate.setDate(minDate.getDate() + 1);

  const maxDate = new Date(applicantEndDate);
  maxDate.setDate(maxDate.getDate() - 1);

  return (
    <>
      <div
        className={`flex w-full lg:w-5/12 mb-4 ${
          currentLanguageCode === 'ar' ? 'lg:ml-4' : 'lg:mr-4'
        }`}
      >
        <div className='flex flex-col bg-white rounded-lg shadow-lg hover:shadow-xl w-full border h-96 p-4'>
          <div className='flex'>
            <span className='font-bold text-xl text-midnightBlue'>
              {t('dashboard:total_applicants')}
            </span>
          </div>
          <div className='flex'>
            <ApplicantAnalytics getJobsAppCount={getAppMetrics} />
          </div>
          <div className='flex justify-center'>
            <div
              className={`flex items-center ${
                currentLanguageCode === 'ar' ? 'ml-3' : 'mr-3'
              }`}
            >
              <span
                className={`w-4 h-4 rounded-full bg-gradient-to-r from-219653 to-44A76E ${
                  currentLanguageCode === 'ar' ? 'ml-1' : 'mr-1'
                }`}
              ></span>{' '}
              <span
                className={`text-black ${
                  currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                }`}
              >
                {t('common:hired')}
              </span>
            </div>
            <div className='flex items-center mx-3'>
              <span className='w-4 h-4 rounded-full bg-gradient-to-r from-primary-rose-shade-4 to-primary-rose-shade-2 mr-1'></span>{' '}
              <span
                className={`text-black ${
                  currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                }`}
              >
                {t('common:inprogress')}
              </span>
            </div>
            <div
              className={`flex items-center ${
                currentLanguageCode === 'ar' ? 'mr-3' : 'ml-3'
              }`}
            >
              <span
                className={`w-4 h-4 rounded-full bg-gradient-to-r from-FF7676 to-FB5151 ${
                  currentLanguageCode === 'ar' ? 'ml-1' : 'mr-1'
                }`}
              ></span>{' '}
              <span
                className={`text-black ${
                  currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                }`}
              >
                {t('common:rejected')}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex w-full lg:w-7/12 mb-4 ${
          currentLanguageCode === 'ar' ? 'lg:mr-4' : 'lg:ml-4'
        }`}
      >
        <div className='flex flex-col bg-white rounded-lg shadow-lg hover:shadow-xl w-full border h-96 p-4'>
          <div className='flex justify-between'>
            <span className='font-bold text-xl text-midnightBlue'>
              {t('dashboard:applicant_metrics')}
            </span>
            <div className='flex searchFunctionality w-64'>
              <div
                className={`p-field p-col-12 p-md-4 ${
                  currentLanguageCode === 'ar' ? 'ml-8' : 'mr-8'
                }`}
              >
                <p className='relative'>
                  <span
                    className={`absolute top-1 w-5 h-5 text-2A2515 ${
                      currentLanguageCode === 'ar' ? 'left-32' : 'right-32'
                    }`}
                  >
                    {t('common:from')}:
                  </span>
                </p>
                <label>
                  <Calendar
                    placeholder={dayjs(applicantStartDate).format('DD-MM-YYYY')}
                    id='basic1'
                    dateFormat='dd-mm-yy'
                    value={new Date(applicantStartDate)}
                    onChange={(e) =>
                      SetApplicantStartDate(dayjs(e.value).format())
                    }
                    readOnlyInput
                    maxDate={maxDate}
                  />
                  <span className='relative'>
                    <DatePick
                      className={`absolute bottom-0 w-5 h-5 ${
                        currentLanguageCode === 'ar' ? 'left-1' : 'right-1'
                      }`}
                    />
                  </span>
                </label>
              </div>{' '}
              <div
                className={`p-field p-col-12 p-md-4 ${
                  currentLanguageCode === 'ar' ? 'ml-3' : 'mr-3'
                }`}
              >
                <p className='relative'>
                  <span
                    className={`absolute top-1 w-5 h-5 text-2A2515 ${
                      currentLanguageCode === 'ar' ? 'left-28' : 'right-28'
                    }`}
                  >
                    {t('common:to')}:
                  </span>
                </p>
                <label>
                  <Calendar
                    placeholder={dayjs(applicantEndDate).format('DD-MM-YYYY')}
                    id='basic2'
                    dateFormat='dd-mm-yy'
                    value={new Date(applicantEndDate)}
                    readOnlyInput
                    minDate={minDate}
                    onChange={(e) =>
                      SetApplicantEndDate(dayjs(e.value).format())
                    }
                  />
                  <span className='relative'>
                    <DatePick
                      className={`absolute bottom-0 w-5 h-5 ${
                        currentLanguageCode === 'ar' ? 'left-1' : 'right-1'
                      }`}
                    />
                  </span>
                </label>
              </div>{' '}
            </div>
          </div>
          <div className='p-4'>
            <JobMetricsAnalytics
              getJobMetrics={getAppMetrics}
              isAppMetrics={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplicantSection;
