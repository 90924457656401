import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import cookies from 'js-cookie';

import { useTranslation } from 'react-i18next';

import PrimaryButton from 'components/Button/primaryButton';

import ReloginModal from 'components/reloginModal';

import { AuthContext } from 'contexts/authContext.js';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { ErrorToast } from 'services/toasterService';

const Login = () => {
  const { t } = useTranslation(['common', 'authentication']);
  const { authDispatch } = useContext(AuthContext);
  const currentLanguageCode = cookies.get('i18next');

  const [showReloginModal, setShowReloginModal] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(false);

  // eslint-disable-next-line
  const [isLoginData, setIsLoginData] = useState(false);

  const [loginData, setLoginData] = useState({});

  const history = useHistory();

  const defaultValues = {
    email: '',
    password: '',
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const loginMutation = useMutation(async (data) => {
    const res = await makeAPIRequest.post(apiEndPoints.LOGIN, data);
    return res.data;
  });

  const handelReloginMudataion = () => {
    loginMutation.mutate(
      { ...loginData, re_login: true },
      {
        onSuccess: (data) => {
          setShowReloginModal(false);
          reset();
          authDispatch({
            type: 'LOGIN',
            payload: data,
          });
          history.replace('/dashboard');
        },
        onError: (err) => {
          const errString = err?.response?.data?.data;
          if (
            err?.response?.data?.data.includes(
              'User matching query does not exist'
            )
          ) {
            ErrorToast({ text: 'User does not exist' });
          } else {
            const errMsg = errString.split("string='")[1].split("'")[0];
            if (errMsg) {
              ErrorToast({ text: errMsg });
            } else {
              ErrorToast({ text: err?.response?.data?.message });
            }
          }
        },
      }
    );
  };

  const onSubmit = (loginData) => {
    setLoginData(loginData);
    loginMutation.mutate(loginData, {
      onSuccess: (data) => {
        if (data.message === 'user have active session') {
          setShowReloginModal(true);
        } else {
          setShowReloginModal(false);
          reset();
          authDispatch({
            type: 'LOGIN',
            payload: data,
          });
          history.replace('/dashboard');
        }
      },
      onError: (err) => {
        const errString = err?.response?.data?.data;
        if (
          err?.response?.data?.data.includes(
            'User matching query does not exist'
          )
        ) {
          ErrorToast({ text: 'User does not exist' });
        } else {
          const errMsg = errString.split("string='")[1].split("'")[0];
          if (errMsg) {
            ErrorToast({ text: errMsg });
          } else {
            ErrorToast({ text: err?.response?.data?.message });
          }
        }
        setIsLoginData(!isLoginData);
      },
    });
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className='p-error'>{errors[name].message}</small>
    );
  };

  return (
    <>
      <div
        className={`auth-bg-overlay max-w-main-page mx-auto ${
          currentLanguageCode === 'ar' ? 'auth-bg-left' : 'auth-bg-right'
        }`}
      >
        <div className='grid grid-cols-4 pt-12 sm:pt-6 md:pt-10 px-4 relative h-86vh'>
          <div className='bigtab:col-span-2 lg:col-span-2 col-span-4 relative'>
            <div className='px-2 sm:px-6 absolute w-full top-12'>
              <div>
                <p className='text-xl text-center font-bold sm:text-2xl text-primary-rose '>
                  {t('authentication:hey_there_welcome_back')}
                </p>
                <p className='text-center font-bold text-primary-rose mt-4'>
                  Smart <span>Hire</span> Admin
                </p>
              </div>

              <div className='my-4 px-2 sm:px-4 sm:w-8/12 lg:w-4/5  bigtab:w-full lg:max-w-auth-form mx-auto'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='w-full my-8'>
                    <span className='p-float-label'>
                      <Controller
                        name='email'
                        control={control}
                        rules={{
                          required: 'This is a required field',
                          // pattern: {
                          //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          //   message: 'Invalid email address.',
                          // },
                        }}
                        render={({ field }) => (
                          <InputText id={field.name} {...field} />
                        )}
                      />
                      <label htmlFor='email'>{t('common:email')}*</label>
                    </span>
                    {getFormErrorMessage('email')}
                  </div>
                  <div className='w-full mt-6 mb-3'>
                    <span className='p-float-label'>
                      <Controller
                        name='password'
                        control={control}
                        rules={{
                          required: 'This is a required field',
                        }}
                        render={({ field }) => (
                          <Password
                            id={field.name}
                            {...field}
                            toggleMask
                            feedback={false}
                          />
                        )}
                      />
                      <label htmlFor='password'>{t('common:password')}*</label>
                    </span>
                    {getFormErrorMessage('password')}
                  </div>

                  <div className='flex justify-between items-center'>
                    <div className='p-field-checkbox flex items-center'>
                      <Checkbox
                        inputId='binary'
                        checked={checkboxValue}
                        onChange={(e) => {
                          setCheckboxValue(e.checked);
                        }}
                      />
                      <label htmlFor='binary' className='text-sm mx-2'>
                        {t('authentication:remember_me')}
                      </label>
                    </div>
                    <p className='text-sm text-primary-rose cursor-pointer hover:underline'>
                      <Link to={'/auth/forgotPassword'}>
                        {t('authentication:forgot_password')}
                      </Link>
                    </p>
                  </div>
                  <div className='w-full flex justify-center items-center mt-4'>
                    <button className='w-2/5 md:w-2/6 xl:w-1/4 text-center cursor-pointer my-4'>
                      <PrimaryButton isLoading={loginMutation.isLoading}>
                        <p className='text-sm leading-5 w-full h-full'>
                          {t('authentication:login')}
                        </p>
                      </PrimaryButton>
                    </button>
                  </div>

                  {/* {history.location.pathname.includes('client') ? null : (
                    <p className='text-center text-sm sm:text-base'>
                      Don’t have a AptaHR account?{' '}
                      <Link to='/auth/register'>
                        <span className='text-primary-rose cursor-pointer hover:underline'>
                          Sign up
                        </span>
                      </Link>
                    </p>
                  )} */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReloginModal
        isReloginModal={showReloginModal}
        setIsReloginModal={setShowReloginModal}
        mutationLogin={handelReloginMudataion}
        loading={loginMutation.isLoading}
      />
    </>
  );
};

export default Login;
