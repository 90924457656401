import { useContext, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';
import { useQueryClient } from 'react-query';

import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { ErrorToast, SuccessToast } from 'services/toasterService';

import { AuthContext } from 'contexts/authContext';

import BaseButton from 'components/Button/baseButton';
import PrimaryButton from 'components/Button/primaryButton';

import { ReactComponent as LogoutIlls } from 'assets/images/authentication/logout_ills.svg';
import { ReactComponent as ForgotPasswordIlls } from 'assets/images/authentication/forgot_password_ills.svg';

export default function LogoutModal({ handelLogoutModal, isLogoutModalOpen }) {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['common']);
  const { authDispatch } = useContext(AuthContext);
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);
  const history = useHistory();

  const queryClient = useQueryClient();

  const header = (
    <div className='z-50 flex justify-end'>
      <svg
        width='40'
        height='40'
        viewBox='0 0 67 65'
        fill='none'
        xmlns='https://www.w3.org/2000/svg'
        className='cursor-pointer'
        onClick={() => handelLogoutModal(false)}
      >
        <path
          d='M41.3962 24.3962L24.9824 40.2906'
          stroke='#EB249E'
          strokeWidth='5.70168'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M24.9824 24.3962L41.3962 40.2906'
          stroke='#EB249E'
          strokeWidth='5.70168'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );

  const handelLogout = () => {
    setIsLogoutLoading(true);
    makeAPIRequest
      .post(apiEndPoints.LOGOUT)
      .then((res) => {
        queryClient.invalidateQueries();
        setIsLogoutLoading(false);

        handelLogoutModal(false);

        setTimeout(() => {
          SuccessToast({ text: res.data.message });
        }, 300);

        setTimeout(() => {
          toast.remove();
          authDispatch({
            type: 'LOGOUT',
          });
          history.replace('/auth/login');
        }, 1000);
      })
      .catch((err) => {
        setIsLogoutLoading(false);

        ErrorToast({
          text: err?.response?.data?.message || 'Something went wrong',
        });
      });
  };
  return (
    <div>
      <Dialog
        visible={isLogoutModalOpen}
        onHide={() => handelLogoutModal(false)}
        breakpoints={{ '960px': '75vw' }}
        style={{
          width: '60vw',
          // marginTop: '4rem',
          borderRadius: '1rem',
        }}
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        position='middle'
        resizable={false}
        closable={false}
        header={header}
      >
        <div className='relative w-full z-40'>
          <div className='flex justify-center items-center w-1/2 lg:w-1/3 mx-auto'>
            <LogoutIlls />
          </div>
          <p className='text-xl text-center mt-4'>
            {currentLanguageCode === 'ar' ? (
              <>هل أنت متأكد من أنك تريد تسجيل الخروج؟</>
            ) : (
              <>
                Are you sure you want to{' '}
                <span className='font-bold'>Logout ?</span>
              </>
            )}
          </p>
          <div className='w-full flex justify-center items-center mt-8 mb-20'>
            <button
              className={`w-32 ${
                currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
              }`}
              onClick={() => handelLogoutModal(false)}
            >
              <PrimaryButton>
                <p className='w-full'>{t('common:no')}</p>
              </PrimaryButton>
            </button>
            <button
              className={`w-32 ${
                currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
              }`}
              onClick={handelLogout}
            >
              <BaseButton isLoading={isLogoutLoading}>
                {t('common:yes')}
              </BaseButton>
            </button>
          </div>
        </div>
        <div className='absolute bottom-6 w-full h-auto left-0'>
          <ForgotPasswordIlls />
        </div>
      </Dialog>
    </div>
  );
}
