import React, { Suspense, useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { ReactComponent as SearchIcon } from 'assets/images/team/searchIcon.svg';
// import { ReactComponent as FilterIcon } from 'assets/images/team/filterIcon.svg';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import queryString from 'query-string';
import LottiLoader from 'components/LottiLoader';
import NoPageFound from 'pages/common/error/noPageFound';
import BasePrivateLayout from 'pages/basePrivateLayout';
import NewRequests from './pages/NewRequests';
import ConfirmedRequests from './pages/ConfirmedRequests';
import InprogressRequests from './pages/InprogressRequests';
import DeletedRequests from './pages/DeletedRequests';
// import { Dropdown } from 'primereact/dropdown';

export default function QuotationRequest() {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['quotation']);
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const queryStringValues = queryString.parse(location.search);

  const [searchFieldValue, setSearchFieldValue] = useState(
    queryStringValues.search ?? ''
  );

  useEffect(() => {
    if (queryStringValues.search) {
      setSearchFieldValue(queryStringValues.search);
    } else {
      setSearchFieldValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, queryStringValues?.tab]);

  // const [sortFieldValue, setSortFieldValue] = useState();

  return (
    <BasePrivateLayout>
      <div className='w-full max-w-screen-xl mx-auto'>
        <div className=' flex justify-between mt-6 text-base 2xl:text-lg font-normal '>
          <div className='flex flex-1 justify-between'>
            <div className='flex'>
              <span
                className={`flex items-end pb-2 cursor-pointer mx-4 ${
                  location.pathname.includes('new')
                    ? 'text-neutrals-black border-b-2 border-gradient font-semibold'
                    : 'text-9B9B9B'
                }`}
                onClick={async () => {
                  history.push({
                    pathname: `${path}/new`,
                    search: 'tab=all',
                  });
                }}
              >
                {t('quotation:new_requests')}
                {/* <span className='text-primary-rose font-bold ml-1 w-5'>
              {location.pathname.includes('/HiringManager') ? (
                clientDataCards?.data?.pages[0]?.filter_count
              ) : (
                <>{clientDataCards?.data?.pages[0]?.total_hm ?? '00'}</>
              )}
            </span> */}
              </span>
              <span
                className={`flex items-end pb-2 cursor-pointer mx-4  ${
                  location.pathname.includes('confirmed')
                    ? 'text-neutrals-black border-b-2 border-gradient font-semibold'
                    : 'text-9B9B9B'
                }`}
                onClick={async () => {
                  history.push({
                    pathname: `${path}/confirmed`,
                    search: 'tab=all',
                  });
                }}
              >
                {t('quotation:confirmed_requests')}
                {/* <span className='text-primary-rose font-bold ml-1 w-5'>
              {location.pathname.includes('/Recruiter') ? (
                clientDataCards?.data?.pages[0]?.filter_count
              ) : (
                <>{clientDataCards?.data?.pages[0]?.total_rec ?? '00'}</>
              )}
            </span> */}
              </span>
              <span
                className={`flex items-end pb-2 cursor-pointer mx-4  ${
                  location.pathname.includes('in-progress')
                    ? 'text-neutrals-black border-b-2 border-gradient font-semibold'
                    : 'text-9B9B9B'
                }`}
                onClick={async () => {
                  history.push({
                    pathname: `${path}/in-progress`,
                    search: 'tab=all',
                  });
                }}
              >
                {t('quotation:in_progress')}
                {/* <span className='text-primary-rose font-bold ml-1 w-5'>
              {location.pathname.includes('/Recruiter') ? (
                clientDataCards?.data?.pages[0]?.filter_count
              ) : (
                <>{clientDataCards?.data?.pages[0]?.total_rec ?? '00'}</>
              )}
            </span> */}
              </span>
            </div>

            <div className='flex'>
              <span
                className={`flex items-end pb-2 cursor-pointer mx-4  ${
                  location.pathname.includes('deleted')
                    ? 'text-neutrals-black border-b-2 border-gradient font-semibold'
                    : 'text-9B9B9B'
                }`}
                onClick={async () => {
                  history.push({
                    pathname: `${path}/deleted`,
                    search: 'tab=all',
                  });
                }}
              >
                {t('quotation:deleted_requests')}
                {/* <span className='text-primary-rose font-bold ml-1 w-5'>
              {location.pathname.includes('/Recruiter') ? (
                clientDataCards?.data?.pages[0]?.filter_count
              ) : (
                <>{clientDataCards?.data?.pages[0]?.total_rec ?? '00'}</>
              )}
            </span> */}
              </span>
            </div>
          </div>

          <div className='flex justify-end items-end searchFunctionality'>
            {/* <div className='mx-2 flex'>
              <span className='sortIcon flex items-center'>
                <FilterIcon />
              </span>
              <span className='p-input-icon-left w-40'>
                <Dropdown
                  value={sortFieldValue}
                  // options={getJobsAppCountSingle?.data?.data?.list_of_jobs}
                  onChange={(e) => {
                    setSortFieldValue(e.target.value);
                  }}
                  optionLabel='jobtitle'
                  optionValue='_id'
                  showClear
                  placeholder='Filter'
                />
              </span>
            </div> */}

            <div className='mx-2 flex'>
              <span className='sortIcon flex items-center'>
                <SearchIcon />
              </span>

              <span className=''>
                <InputText
                  id='searchField3'
                  value={searchFieldValue}
                  onChange={({ currentTarget }) => {
                    let queryStringValues2 = {
                      search: currentTarget.value,
                    };
                    const searchString =
                      queryString.stringify(queryStringValues2);
                    history.replace({
                      pathname: location.pathname,
                      search: `?tab=${queryStringValues?.tab}&${searchString}`,
                    });
                    setSearchFieldValue(currentTarget.value);
                  }}
                  placeholder={t('quotation:search_by_name')}
                  className={`py-1 ${
                    currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                  }`}
                  autoComplete='off'
                />
              </span>
            </div>
          </div>
        </div>
        <Suspense
          fallback={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className='max-w-main-page h-60vh flex justify-center items-center '>
                <LottiLoader />
              </div>
            </div>
          }
        >
          <Switch>
            <Redirect exact from={path} to={`${path}/new`} />
            <Route path={`${path}/new`} component={NewRequests} />
            <Route path={`${path}/confirmed`} component={ConfirmedRequests} />
            <Route
              path={`${path}/in-progress`}
              component={InprogressRequests}
            />
            <Route path={`${path}/deleted`} component={DeletedRequests} />

            {/* DO NOT CHNAGE this empty route position Add everything above this line */}
            <Route component={NoPageFound} />
          </Switch>
        </Suspense>
      </div>
    </BasePrivateLayout>
  );
}
