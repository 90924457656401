import React, { Fragment, useState } from 'react';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
// import { ReactComponent as SuccessIcon } from 'assets/icons/SuccessIcon.svg';
import { ReactComponent as YellowDownload } from 'assets/icons/YellowDownload.svg';
import { ReactComponent as GreenDownload } from 'assets/icons/GreenDownload.svg';
import { ReactComponent as NoClient } from 'assets/images/hiringmanager/noclients.svg';
import dayjs from 'dayjs';
import LottiLoader from 'components/LottiLoader';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Popover, Transition } from '@headlessui/react';
import { ErrorToast, SuccessToast } from 'services/toasterService';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as ThreeDots } from 'assets/images/hiringmanager/threedots.svg';
import BaseButton from 'components/Button/baseButton';
import { saveAs } from 'file-saver';
import Accept from '../components/accept';
import Decline from '../components/decline';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import Tip from 'components/Tooltip';

export default function InprogressRequests() {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['common', 'quotation']);
  const [isAcceptDialog, setAcceptDialog] = useState(false);
  const [isDeleteDialog, setDeleteDialog] = useState(false);
  const [quoteData, setQuoteData] = useState('');
  const location = useLocation();
  const queryStringValues = queryString.parse(location.search);

  const { path } = useRouteMatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  const InprogressRequests = useQuery(
    ['InprogressRequests', queryStringValues.search, queryStringValues?.tab],
    async () => {
      const resData = await makeAPIRequest.get(
        `${apiEndPoints.SEND_QUOTATION_REQUEST}?is_deleted=False${
          queryStringValues?.tab === 'hm'
            ? '&for_hr=True'
            : queryStringValues?.tab === 'rec'
            ? '&for_hr=False'
            : ''
        }${
          queryStringValues.search ? '&search=' + queryStringValues.search : ''
        }`
      );
      return resData?.data;
    },
    {
      onSuccess: (data) => {},
    }
  );

  const updateQuotationMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.put(
        apiEndPoints.SEND_QUOTATION_REQUEST,
        data
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('newRequests');
        queryClient.invalidateQueries('InprogressRequests');
        queryClient.invalidateQueries('confirmedRequests');
        queryClient.invalidateQueries('DeletedRequests');
        SuccessToast({ text: data.message });
        setDeleteDialog(false);
      },
      onError: (err) => {
        ErrorToast({ text: err.response.data.message });
      },
    }
  );

  const markAsPaidMudation = useMutation(
    async (apiData) => {
      const data = await makeAPIRequest.post(
        apiEndPoints.QUOTATION_MARK_AS_PAID,
        apiData
      );
      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('newRequests');
        queryClient.invalidateQueries('InprogressRequests');
        queryClient.invalidateQueries('confirmedRequests');
        queryClient.invalidateQueries('DeletedRequests');
        SuccessToast({ text: data.message });
        setAcceptDialog(false);
      },
      onError: (err) => {
        ErrorToast({ text: err.response.data.message });
      },
    }
  );

  return (
    <div className='border mt-6 mx-2 rounded-xl shadow-lg bg-white'>
      <div className='flex mt-4'>
        <span
          className={`flex items-end pb-2 cursor-pointer mx-4 ${
            queryStringValues?.tab === 'all'
              ? 'text-neutrals-black border-b-2 border-gradient font-semibold'
              : 'text-9B9B9B'
          }`}
          onClick={async () => {
            history.push({
              pathname: `${path}`,
              search: 'tab=all',
            });
          }}
        >
          {t('common:all')}
        </span>
        <span
          className={`flex items-end pb-2 cursor-pointer mx-4 ${
            queryStringValues?.tab === 'hm'
              ? 'text-neutrals-black border-b-2 border-gradient font-semibold'
              : 'text-9B9B9B'
          }`}
          onClick={async () => {
            history.push({
              pathname: `${path}`,
              search: 'tab=hm',
            });
          }}
        >
          {t('common:hiring_manager')}
        </span>
        <span
          className={`flex items-end pb-2 cursor-pointer mx-4 ${
            queryStringValues?.tab === 'rec'
              ? 'text-neutrals-black border-b-2 border-gradient font-semibold'
              : 'text-9B9B9B'
          }`}
          onClick={async () => {
            history.push({
              pathname: `${path}`,
              search: 'tab=rec',
            });
          }}
        >
          {t('common:recruiter')}
        </span>
      </div>
      {InprogressRequests.isLoading ? (
        <div className='pt-5 md:px-5 lg:px-0 '>
          <div className='rounded-lg w-full h-70vh bg-white p-8'>
            <div className='flex justify-center items-center w-full h-full'>
              <LottiLoader />
            </div>
          </div>
        </div>
      ) : (
        <>
          {' '}
          {InprogressRequests?.data?.data?.length > 0 ? (
            <>
              <div className='md:px-5 lg:px-0 '>
                <div className='rounded-lg w-full h-full bg-white p-8'>
                  <div
                    className={`flex w-full border-b-1 border-opacity-50 border-primary-rose text-base font-bold text-black pb-4 ${
                      currentLanguageCode === 'ar' ? 'pl-4' : 'pr-4'
                    }`}
                  >
                    <div className='w-1/4 flex'>
                      <p className='flex flex-col w-1/2 text-center'>
                        {t('quotation:request_id')}
                      </p>
                      <p className='flex flex-col w-1/2 text-center'>
                        {t('common:date')}
                      </p>
                    </div>
                    <div className='w-3/4 flex'>
                      <div className='w-3/5 flex'>
                        <p className='flex flex-col w-1/3 text-center'>
                          {t('common:name')}
                        </p>
                        <p className='flex flex-col w-1/3 text-center'>
                          {t('common:company_name')}
                        </p>
                        <p className='flex flex-col w-1/3 text-center'>
                          {t('quotation:contact_details')}
                        </p>
                      </div>
                      <div className='w-2/5 flex'>
                        <p className='flex flex-col w-2/5 items-center text-center'>
                          {t('quotation:quotation_id_and_price')}
                        </p>
                        <p className='flex flex-col w-3/5 items-center text-center'>
                          {t('quotation:action')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`flex flex-col w-full h-full max-h-60vh pb-14 scroller overflow-y-scroll ${
                      currentLanguageCode === 'ar' ? 'pl-2' : 'pr-2'
                    }`}
                  >
                    {InprogressRequests?.data?.data.map(
                      (newrequest, index, array) => (
                        <div
                          key={newrequest.id}
                          className={`flex w-full text-Neutrals-gray-1 xl:py-7 lg:py-4  md:py-4 text-center ${
                            index + 1 === array.length
                              ? ''
                              : 'border-b-1 border-opacity-50 border-primary-rose'
                          }  `}
                        >
                          <div className='w-1/4 flex'>
                            <p className='flex flex-col w-1/2 text-primary-rose'>
                              {newrequest?.q_request_id
                                ? newrequest?.q_request_id
                                : '-'}
                            </p>
                            <p className='flex flex-col w-1/2'>
                              {newrequest?.created_at
                                ? dayjs(newrequest?.created_at).format(
                                    'DD MMM YYYY'
                                  )
                                : '-'}
                            </p>
                          </div>
                          <div className='w-3/4 flex'>
                            <div className='w-3/5 flex'>
                              <p className='flex flex-col w-1/3 text-black font-semibold truncate'>
                                {newrequest?.name ? newrequest?.name : '-'}
                              </p>
                              <p className='flex flex-col w-1/3'>
                                {newrequest?.company_name
                                  ? newrequest?.company_name
                                  : '-'}
                              </p>
                              <div className='flex flex-col w-1/3'>
                                <p className=''>
                                  {newrequest?.contact_number ? (
                                    <span>
                                      {newrequest?.country_code}{' '}
                                      {newrequest?.contact_number}
                                    </span>
                                  ) : (
                                    '-'
                                  )}
                                </p>
                                <Tip content={newrequest?.email}>
                                  <p className='truncate px-4'>
                                    {newrequest?.email
                                      ? newrequest?.email
                                      : '-'}
                                  </p>
                                </Tip>
                              </div>
                            </div>
                            <div className='w-2/5 flex'>
                              <div className='flex flex-col w-2/5 items-center'>
                                <p className=''>
                                  <span>
                                    #{newrequest?.id ? newrequest?.id : '-'}
                                  </span>
                                </p>
                                <p className=''>
                                  SR{' '}
                                  {newrequest?.total_value
                                    ? newrequest?.total_value
                                    : '-'}
                                </p>
                              </div>
                              <div
                                className={`flex w-3/5 items-center justify-center ${
                                  currentLanguageCode === 'ar' ? 'mr-4' : 'ml-4'
                                }`}
                              >
                                <a
                                  href={newrequest?.pdf_url}
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  <button
                                    className={`w-16 ${
                                      currentLanguageCode === 'ar'
                                        ? 'ml-5'
                                        : 'mr-5'
                                    }`}
                                    onClick={() => {
                                      // setPopupOpen(true);
                                      // setCustomerData(newrequest);
                                    }}
                                  >
                                    <BaseButton>
                                      <p className='text-sm leading-4'>
                                        {t('common:view')}
                                      </p>
                                    </BaseButton>
                                  </button>
                                </a>

                                {newrequest.is_paid ? (
                                  <button
                                    onClick={() => {
                                      saveAs(newrequest?.pdf_url);
                                    }}
                                    className={`${
                                      currentLanguageCode === 'ar'
                                        ? 'ml-4'
                                        : 'mr-4'
                                    }`}
                                  >
                                    <GreenDownload />
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => {
                                      saveAs(newrequest?.pdf_url);
                                    }}
                                    className={`${
                                      currentLanguageCode === 'ar'
                                        ? 'ml-4'
                                        : 'mr-4'
                                    }`}
                                  >
                                    <YellowDownload />
                                  </button>
                                )}
                                {newrequest.is_paid ? (
                                  <div
                                    className={`w-7 ${
                                      currentLanguageCode === 'ar'
                                        ? 'ml-4'
                                        : 'mr-4'
                                    }`}
                                  ></div>
                                ) : (
                                  <div
                                    className={`${
                                      currentLanguageCode === 'ar'
                                        ? 'mr-1 ml-4'
                                        : 'ml-1 mr-4'
                                    }`}
                                  >
                                    <Popover className='relative'>
                                      {() => (
                                        <>
                                          <Popover.Button
                                            className={`group bg-rose-700 px-1 py-1 rounded-md inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                                          >
                                            <div className='  cursor-pointer '>
                                              <ThreeDots className='w-4 h-4 ' />
                                            </div>
                                          </Popover.Button>
                                          <Transition
                                            as={Fragment}
                                            enter='transition ease-out duration-200'
                                            enterFrom='opacity-0 translate-y-1'
                                            enterTo='opacity-100 translate-y-0'
                                            leave='transition ease-in duration-150'
                                            leaveFrom='opacity-100 translate-y-0'
                                            leaveTo='opacity-0 translate-y-1'
                                          >
                                            <Popover.Panel className='absolute z-40 w-32 mt-3 right-0 '>
                                              <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                                                <div className='relative grid gap-6 bg-white px-4 py-5'>
                                                  <div
                                                    className='flex items-center py-1 cursor-pointer -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-rose-500 focus-visible:ring-opacity-50'
                                                    onClick={() => {
                                                      setQuoteData(newrequest);
                                                      setAcceptDialog(true);
                                                    }}
                                                  >
                                                    <div
                                                      className={`${
                                                        currentLanguageCode ===
                                                        'ar'
                                                          ? 'mr-2'
                                                          : 'ml-2'
                                                      }`}
                                                    >
                                                      <p className='text-sm font-medium text-gray-900'>
                                                        {t(
                                                          'quotation:mark_as_paid'
                                                        )}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className='flex items-center py-1 cursor-pointer -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-rose-500 focus-visible:ring-opacity-50'
                                                    onClick={() => {
                                                      setQuoteData(newrequest);
                                                      setDeleteDialog(true);
                                                    }}
                                                  >
                                                    <div
                                                      className={`${
                                                        currentLanguageCode ===
                                                        'ar'
                                                          ? 'mr-2'
                                                          : 'ml-2'
                                                      }`}
                                                    >
                                                      <p className='text-sm font-medium text-gray-900'>
                                                        {t('quotation:delete')}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Popover.Panel>
                                          </Transition>
                                        </>
                                      )}
                                    </Popover>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <Accept
                isAccept={isAcceptDialog}
                setAccept={setAcceptDialog}
                quoteData={quoteData}
                mutation={markAsPaidMudation}
              />
              <Decline
                isDecline={isDeleteDialog}
                setDecline={setDeleteDialog}
                quoteData={quoteData}
                mutation={updateQuotationMutation}
              />
            </>
          ) : (
            <div className='flex h-60vh justify-center items-center text-center'>
              <div>
                <div
                  className={`${
                    currentLanguageCode === 'ar'
                      ? 'lg:mr-10 md:mr-12'
                      : 'lg:ml-10 md:ml-12'
                  }`}
                >
                  <NoClient />
                </div>
                <div className='text-Neutrals-gray-1   text-xl flex  pt-2 '>
                  {t('quotation:no_requests_to_show')}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
