import React, { useState, useRef } from 'react';
import { useQuery } from 'react-query';
import makeAPIRequest from 'services/makeApiRequest';
import queryString from 'query-string';
import apiEndPoints from 'services/apiEndpoints';
import { Transition } from '@headlessui/react';

import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import { InputText } from 'primereact/inputtext';
import { ReactComponent as SearchIcon } from 'assets/images/team/searchIcon.svg';
import Avatar from 'components/Avatar';
import { Link } from 'react-router-dom';

function SearchField({ history, queryStringValues, location, isClientPage }) {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['dashboard', 'common']);
  const [searchFieldValue, setSearchFieldValue] = useState();
  const [clientTypeValue, setClientTypeValue] = useState('hm');

  const [showDropDown, setShowDropdown] = useState(false);

  const getClientSearchResults = useQuery(
    ['getClientSearchResults', searchFieldValue, clientTypeValue],
    async () => {
      let resData;
      if (!!searchFieldValue) {
        resData = await makeAPIRequest.get(
          `${
            apiEndPoints.AdminDashClientSearchAPI
          }?search=${searchFieldValue}&${
            clientTypeValue === 'hm'
              ? 'user__is_hiring_manager=True'
              : 'user__is_recruiter=True'
          }`
        );
      } else {
        resData = await makeAPIRequest.get(
          `${apiEndPoints.AdminDashClientSearchAPI}?${
            clientTypeValue === 'hm'
              ? 'user__is_hiring_manager=True'
              : 'user__is_recruiter=True'
          }`
        );
      }
      return resData?.data;
    },
    {
      onSuccess: (data) => {},
    }
  );

  const searchDropdownRef = useRef(null);

  const useClickOutside = (ref, callback) => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
    React.useEffect(() => {
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    });
  };

  useClickOutside(searchDropdownRef, () => {
    setShowDropdown(false);
  });

  return (
    <div className='relative flex mt-2' ref={searchDropdownRef}>
      <div className='flex items-center justify-end'>
        <div className='mx-2 flex' onClick={() => setShowDropdown(true)}>
          <span className='sortIcon flex items-center'>
            <SearchIcon />
          </span>

          <span className='w-72'>
            <InputText
              id='searchField'
              value={searchFieldValue}
              onChange={(e) => {
                setSearchFieldValue(e.target.value);
                let queryStringValues2;
                if (queryStringValues && !isClientPage) {
                  queryStringValues2 = {
                    ...queryStringValues,
                    search: e.target.value,
                  };
                } else {
                  queryStringValues2 = {
                    search: e.target.value,
                  };
                }
                const searchString = queryString.stringify(queryStringValues2);
                history.replace({
                  pathname: location.pathname,
                  search: `?${searchString}`,
                });
              }}
              placeholder={t('dashboard:search_with_client_name_or_email')}
              className={`${currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'}`}
              autoComplete='off'
            />
          </span>
        </div>
        <Transition show={showDropDown}>
          <div className='absolute right-0 top-10 flex mt-2 z-40 h-70vh'>
            <div className='flex w-80 bg-white shadow-lg border rounded-lg'>
              <div className='flex flex-col w-full'>
                <div className='flex p-4'>
                  <button
                    onClick={() => setClientTypeValue('hm')}
                    className={`${
                      clientTypeValue === 'hm'
                        ? `rounded-full py-2 px-4 bg-F2F2F2 text-2A2515 text-xs ${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`
                        : `rounded-full py-2 px-4 hover:bg-F2F2F2 bg-white border border-C6C6C6 hover:border-F2F2F2 text-2A2515 text-xs ${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`
                    } `}
                  >
                    {t('common:hiring_manager')}
                  </button>
                  <button
                    onClick={() => setClientTypeValue('rec')}
                    className={`${
                      clientTypeValue === 'rec'
                        ? `rounded-full py-2 px-4 bg-F2F2F2 text-2A2515 text-xs ${
                            currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                          }`
                        : `rounded-full py-2 px-4 hover:bg-F2F2F2 bg-white border border-C6C6C6 hover:border-F2F2F2 text-2A2515 text-xs ${
                            currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                          }`
                    } `}
                  >
                    {t('common:recruiter')}
                  </button>
                </div>
                {getClientSearchResults?.data?.data?.length ? (
                  <div className='flex flex-col w-full h-full overflow-y-auto scroller px-4 pb-4'>
                    {getClientSearchResults?.data?.data?.map((data) => (
                      <Link
                        to={`/manageClients/singleclient/${data.id}`}
                        key={data?.id}
                        onClick={() => setShowDropdown(false)}
                      >
                        <div className='flex my-2 pb-4 cursor-pointer border-b-1 border-E0E0E0'>
                          <Avatar
                            imgSrc={data?.company_logo}
                            className='inline object-cover w-10 h-10 rounded-full'
                            userInitials={`${data?.company_name
                              .charAt(0)
                              .toUpperCase()}`}
                          />
                          <div
                            className={`flex flex-col justify-center ${
                              currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                            }`}
                          >
                            <p className='flex text-sm text-neutrals-black'>
                              {data.company_name}
                            </p>
                            <p className='flex text-sm text-Neutrals-gray-1'>
                              {data.email}
                            </p>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <div className='flex flex-col w-full mt-6'>
                    <div className='flex justify-center my-4'>
                      {t('dashboard:no_results_found')}!
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}

export default SearchField;
