import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// import ScrollToTop from './scroolToTop';

import ProtectedRoute from './ProtectedRoute';
import AuthRoutes from 'pages/common/Authentication';
import CheckAuthPage from 'pages/checkAuthPage';
import AssessmentBank from 'pages/common/AssessmentBank';
import Dashboard from 'pages/common/Dashboard';
import ManageClients from 'pages/common/ManageClients';
import Demo from 'pages/common/Demo';
import SkillTraningPage from 'pages/common/skillTraining';
import NoPageFound from 'pages/common/error/noPageFound';
import QuotationRequest from 'pages/common/QuotationRequest';

const AppRoutes = () => {
  return (
    <>
      <Router>
        {/* <ScrollToTop /> */}
        <Switch>
          <Route exact path='/'>
            <CheckAuthPage />
          </Route>
          <Route path='/auth'>
            <AuthRoutes />
          </Route>

          {/* <ProtectedRoute path='/profile' component={ProfileRoutes} /> */}
          {/* <ProtectedRoute path='/helpSupport' component={HelpSupport} /> */}
          {/* <ProtectedRoute path='/teams' component={TeamsPage} /> */}
          <ProtectedRoute path='/assessmentBank' component={AssessmentBank} />
          <ProtectedRoute path='/dashboard' component={Dashboard} />
          <ProtectedRoute path='/manageClients' component={ManageClients} />
          <ProtectedRoute path='/demo' component={Demo} />
          <ProtectedRoute path='/skill-training' component={SkillTraningPage} />
          <ProtectedRoute
            path='/quotation-requests'
            component={QuotationRequest}
          />
          {/* <ProtectedRoute path='/jobs' component={HRJobsPage} /> */}

          {/* DO NOT CHNAGE this empty route position Add everything above this line */}
          <Route component={NoPageFound} />
        </Switch>
      </Router>
    </>
  );
};

export default AppRoutes;
