import React from 'react';

import ClientAnalytics from './ClientAnalytics';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

function ClientSection({ getClientAnalytics, history }) {
  const { t } = useTranslation(['dashboard']);
  const currentLanguageCode = cookies.get('i18next');

  return (
    <>
      <div
        className={`flex w-full ${
          currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
        }`}
      >
        <div className='flex flex-col bg-white rounded-lg shadow-lg hover:shadow-xl w-full border h-96 p-4'>
          <div className='flex w-full justify-between'>
            <span className='font-bold text-xl text-midnightBlue'>
              {t('dashboard:clients')}
            </span>
            <div className='flex'>
              <div
                className={`flex items-center ${
                  currentLanguageCode === 'ar' ? 'ml-3' : 'mr-3'
                }`}
              >
                <span
                  className={`w-4 h-4 rounded-full bg-gradient-to-r from-primary-rose-shade-4 to-primary-rose-shade-1 ${
                    currentLanguageCode === 'ar' ? 'ml-1' : 'mr-1'
                  }`}
                ></span>{' '}
                <span
                  className={`text-black ${
                    currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                  }`}
                >
                  {t('dashboard:active_clients')}
                </span>
              </div>
              <div
                className={`flex items-center ${
                  currentLanguageCode === 'ar' ? 'mr-3' : 'ml-3'
                }`}
              >
                <span
                  className={`w-4 h-4 rounded-full bg-gradient-to-r from-DADADA to-AEAEAE ${
                    currentLanguageCode === 'ar' ? 'ml-1' : 'mr-1'
                  }`}
                ></span>{' '}
                <span
                  className={`text-black ${
                    currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                  }`}
                >
                  {t('dashboard:inactive_clients')}
                </span>
              </div>
            </div>
          </div>
          <ClientAnalytics getClientAnalytics={getClientAnalytics} />
        </div>
      </div>
      <div
        className={`flex lg:flex-col lg:w-96 mt-4 lg:mt-0 ${
          currentLanguageCode === 'ar' ? 'lg:mr-4' : 'lg:ml-4'
        }`}
      >
        <div
          onClick={() =>
            history.push('/manageClients/clientsoverview/Newrequests')
          }
          className={`flex flex-col justify-center items-center cursor-pointer bg-white rounded-lg shadow-lg hover:shadow-xl w-full border h-1/2 lg:mb-2 p-4 ${
            currentLanguageCode === 'ar' ? 'ml-2 lg:ml-0' : 'mr-2 lg:mr-0'
          }`}
        >
          <p className='text-2A2515 text-center '>
            {t('dashboard:new_company_requests')}
          </p>
          <p className='text-gradient text-center text-4xl font-bold my-6'>
            {getClientAnalytics?.data?.data?.new_requests}
          </p>
        </div>
        <div
          className={`flex flex-col justify-center items-center cursor-pointer bg-white rounded-lg shadow-lg w-full border h-1/2 lg:mt-2 p-4 ${
            currentLanguageCode === 'ar' ? 'mr-2 lg:mr-0' : 'ml-2 lg:ml-0'
          }`}
          onClick={() => history.push('/demo')}
        >
          <p className='text-2A2515 text-center '>
            {t('dashboard:demo_requests')}
          </p>
          <p className='text-gradient text-center text-4xl font-bold my-6'>
            {getClientAnalytics?.data?.data?.demo_requests}
          </p>
        </div>
      </div>
    </>
  );
}

export default ClientSection;
