import React, { Fragment } from 'react';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
// import { ReactComponent as SuccessIcon } from 'assets/icons/SuccessIcon.svg';
import { ReactComponent as NoClient } from 'assets/images/hiringmanager/noclients.svg';
import dayjs from 'dayjs';
import LottiLoader from 'components/LottiLoader';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ErrorToast, SuccessToast } from 'services/toasterService';
import BaseButton from 'components/Button/baseButton';
import PrimaryButton from 'components/Button/primaryButton';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import Tip from 'components/Tooltip';

import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

export default function DeletedRequests() {
  const { t } = useTranslation(['common', 'quotation']);
  const currentLanguageCode = cookies.get('i18next');

  const queryClient = useQueryClient();
  const location = useLocation();
  const queryStringValues = queryString.parse(location.search);
  const { path } = useRouteMatch();
  const history = useHistory();

  const DeletedRequests = useQuery(
    ['DeletedRequests', queryStringValues.search, queryStringValues?.tab],
    async () => {
      const resData = await makeAPIRequest.get(
        `${apiEndPoints.SEND_QUOTATION_REQUEST}?is_deleted=True${
          queryStringValues?.tab === 'hm'
            ? '&for_hr=True'
            : queryStringValues?.tab === 'rec'
            ? '&for_hr=False'
            : ''
        }${
          queryStringValues.search ? '&search=' + queryStringValues.search : ''
        }`
      );
      return resData?.data;
    },
    {
      onSuccess: (data) => {},
    }
  );

  const updateQuotationMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.put(
        apiEndPoints.SEND_QUOTATION_REQUEST,
        data
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('newRequests');
        queryClient.invalidateQueries('InprogressRequests');
        queryClient.invalidateQueries('confirmedRequests');
        queryClient.invalidateQueries('DeletedRequests');
        SuccessToast({ text: data.message });
      },
      onError: (err) => {
        ErrorToast({ text: err.response.data.message });
      },
    }
  );

  return (
    <div className='border mt-6 mx-2 rounded-xl shadow-lg bg-white'>
      <div className='flex mt-4'>
        <span
          className={`flex items-end pb-2 cursor-pointer mx-4 ${
            queryStringValues?.tab === 'all'
              ? 'text-neutrals-black border-b-2 border-gradient font-semibold'
              : 'text-9B9B9B'
          }`}
          onClick={async () => {
            history.push({
              pathname: `${path}`,
              search: 'tab=all',
            });
          }}
        >
          {t('common:all')}
        </span>
        <span
          className={`flex items-end pb-2 cursor-pointer mx-4 ${
            queryStringValues?.tab === 'hm'
              ? 'text-neutrals-black border-b-2 border-gradient font-semibold'
              : 'text-9B9B9B'
          }`}
          onClick={async () => {
            history.push({
              pathname: `${path}`,
              search: 'tab=hm',
            });
          }}
        >
          {t('common:hiring_manager')}
        </span>
        <span
          className={`flex items-end pb-2 cursor-pointer mx-4 ${
            queryStringValues?.tab === 'rec'
              ? 'text-neutrals-black border-b-2 border-gradient font-semibold'
              : 'text-9B9B9B'
          }`}
          onClick={async () => {
            history.push({
              pathname: `${path}`,
              search: 'tab=rec',
            });
          }}
        >
          {t('common:recruiter')}
        </span>
      </div>
      {DeletedRequests.isLoading ? (
        <div className='md:px-5 lg:px-0 '>
          <div className='rounded-lg w-full h-70vh bg-white p-8'>
            <div className='flex justify-center items-center w-full h-full'>
              <LottiLoader />
            </div>
          </div>
        </div>
      ) : (
        <>
          {' '}
          {DeletedRequests?.data?.data?.length > 0 ? (
            <>
              <div className='md:px-5 lg:px-0 '>
                <div className='rounded-lg w-full h-full bg-white p-8'>
                  <div
                    className={`flex w-full border-b-1 border-opacity-50 border-primary-rose text-base font-bold text-black pb-4 ${
                      currentLanguageCode === 'ar' ? 'pl-4' : 'pr-4'
                    }`}
                  >
                    <div className='w-1/4 flex'>
                      <p className='flex flex-col w-1/2 text-center'>
                        {t('quotation:request_id')}
                      </p>
                      <p className='flex flex-col w-1/2 text-center'>
                        {t('common:date')}
                      </p>
                    </div>
                    <div className='w-3/4 flex'>
                      <div className='w-3/5 flex'>
                        <p className='flex flex-col w-1/3 text-center'>
                          {t('common:name')}
                        </p>
                        <p className='flex flex-col w-1/3 text-center'>
                          {t('common:company_name')}
                        </p>
                        <p className='flex flex-col w-1/3 text-center'>
                          {t('quotation:contact_details')}
                        </p>
                      </div>
                      <div className='w-2/5 flex'>
                        <p className='flex flex-col w-2/5 items-center text-center'>
                          {t('quotation:quotation_id_and_price')}
                        </p>
                        <p className='flex flex-col w-3/5 items-center text-center'>
                          {t('quotation:action')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`flex flex-col w-full h-full max-h-60vh scroller overflow-y-scroll text-center ${
                      currentLanguageCode === 'ar' ? 'pl-2' : 'pr-2'
                    }`}
                  >
                    {DeletedRequests?.data?.data.map(
                      (newrequest, index, array) => (
                        <div
                          key={newrequest.id}
                          className={`flex w-full text-Neutrals-gray-1 xl:py-7 lg:py-4  md:py-4 ${
                            index + 1 === array.length
                              ? ''
                              : 'border-b-1 border-opacity-50 border-primary-rose'
                          }  `}
                        >
                          <div className='w-1/4 flex'>
                            <p className='flex flex-col w-1/2 text-primary-rose'>
                              {newrequest?.q_request_id
                                ? newrequest?.q_request_id
                                : '-'}
                            </p>
                            <p className='flex flex-col w-1/2'>
                              {newrequest?.created_at
                                ? dayjs(newrequest?.created_at).format(
                                    'DD MMM YYYY'
                                  )
                                : '-'}
                            </p>
                          </div>
                          <div className='w-3/4 flex'>
                            <div className='w-3/5 flex'>
                              <p className='flex flex-col w-1/3 text-black font-semibold truncate'>
                                {newrequest?.name ? newrequest?.name : '-'}
                              </p>
                              <p className='flex flex-col w-1/3'>
                                {newrequest?.company_name
                                  ? newrequest?.company_name
                                  : '-'}
                              </p>
                              <div className='flex flex-col w-1/3'>
                                <p className=''>
                                  {newrequest?.contact_number ? (
                                    <span>
                                      {newrequest?.country_code}{' '}
                                      {newrequest?.contact_number}
                                    </span>
                                  ) : (
                                    '-'
                                  )}
                                </p>
                                <Tip content={newrequest?.email}>
                                  <p className='truncate px-4'>
                                    {newrequest?.email
                                      ? newrequest?.email
                                      : '-'}
                                  </p>
                                </Tip>
                              </div>
                            </div>
                            <div className='w-2/5 flex'>
                              <div className='flex flex-col w-2/5 items-center'>
                                <p className=''>
                                  <span>
                                    #{newrequest?.id ? newrequest?.id : '-'}
                                  </span>
                                </p>
                                <p className=''>
                                  SR{' '}
                                  {newrequest?.total_value
                                    ? newrequest?.total_value
                                    : '-'}
                                </p>
                              </div>
                              <div
                                className={`flex w-3/5 items-center justify-center ${
                                  currentLanguageCode === 'ar' ? 'mr-4' : 'ml-4'
                                }`}
                              >
                                <a
                                  href={newrequest?.pdf_url}
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  <button
                                    className={`w-16 ${
                                      currentLanguageCode === 'ar'
                                        ? 'ml-1'
                                        : 'mr-1'
                                    }`}
                                  >
                                    <BaseButton>
                                      <p className='text-sm leading-4'>
                                        {t('common:view')}
                                      </p>
                                    </BaseButton>
                                  </button>
                                </a>

                                <button
                                  className={`w-16 ${
                                    currentLanguageCode === 'ar'
                                      ? 'mr-5'
                                      : 'ml-5'
                                  }`}
                                  onClick={() => {
                                    updateQuotationMutation.mutate({
                                      id: newrequest.id,
                                      is_deleted: false,
                                    });
                                  }}
                                >
                                  <PrimaryButton>
                                    <p className='text-sm leading-4'>
                                      {t('quotation:restore')}
                                    </p>
                                  </PrimaryButton>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className='flex h-60vh justify-center items-center text-center'>
              <div>
                <div
                  className={` ${
                    currentLanguageCode === 'ar'
                      ? 'lg:mr-10 md:mr-12'
                      : 'lg:ml-10 md:ml-12'
                  }`}
                >
                  <NoClient />
                </div>
                <div className='text-Neutrals-gray-1   text-xl flex  pt-2 '>
                  {t('quotation:no_requests_to_show')}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
