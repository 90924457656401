import React from 'react';
// import Loader from 'components/Loader';
import loadable from '@loadable/component';
import { Skeleton } from 'primereact/skeleton';
import { useTranslation } from 'react-i18next';

import { ReactComponent as NoJobsCount } from 'assets/images/dashboard/NoJobsCount.svg';

const ApplicantsDonutChart = loadable(
  () => import('./charts/ApplicantsDonutChart'),
  {
    fallback: (
      <div className=' flex flex-1 flex-col'>
        <div className='flex flex-1 justify-center min-h-300px max-h-300px flex-col scroller overflow-y-auto'>
          <div className='flex my-4 justify-center'>
            <Skeleton height='12rem' width='12rem' borderRadius='12rem' />
          </div>
        </div>
      </div>
    ),
  }
);

function ApplicantAnalytics({ getJobsAppCount }) {
  const { t } = useTranslation(['dashboard']);
  return (
    <>
      <div className='w-full flex flex-col  px-6 py-0'>
        {getJobsAppCount.isLoading || getJobsAppCount.isFetching ? (
          <div className=' flex flex-1 flex-col'>
            <div className='flex flex-1 justify-center min-h-300px max-h-300px flex-col scroller overflow-y-auto'>
              <div className='flex my-4 justify-center'>
                <Skeleton height='12rem' width='12rem' borderRadius='12rem' />
              </div>
            </div>
          </div>
        ) : getJobsAppCount?.data?.hired_applicants > 0 ||
          getJobsAppCount?.data?.inprogress_applicants > 0 ||
          getJobsAppCount?.data?.rejected_applicants > 0 ? (
          <div className=' flex flex-1 flex-col justify-between'>
            <ApplicantsDonutChart
              chartData={[
                {
                  name: 'Rejected',
                  value: getJobsAppCount?.data?.rejected_applicants || 0,
                  color: '#F272C1',
                  start: '#F272C1',
                  end: '#EF53B3',
                },
                {
                  name: 'Hired',
                  value: getJobsAppCount?.data?.hired_applicants || 0,
                  color: '#219653',
                  start: '#219653',
                  end: '#44A76E',
                },
                {
                  name: 'Inprogress',
                  value: getJobsAppCount?.data?.inprogress_applicants || 0,
                  color: '#F272C1',
                  start: '#F272C1',
                  end: '#EF53B3',
                },
              ]}
              ApplicantData={{
                title: 'Total Applicants',
                value: getJobsAppCount?.data?.total_applicants,
              }}
            />
          </div>
        ) : (
          <div className=' flex justify-center items-center h-72'>
            <div className='flex h-full mt-6 justify-center items-center flex-col'>
              <NoJobsCount />
              <span className='text-Neutrals-gray-1 text-sm mt-6 mb-2'>
                {t('dashboard:no_applicants_to_show_graph')}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ApplicantAnalytics;
