import React, { Suspense } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { lazy } from '@loadable/component';

import Loader from 'components/Loader';

import PublicHeader from 'components/Header/publicHeader';

import Login from './login';
// const Register = lazy(() => import('./register'));
const ForgotPassword = lazy(() => import('./forgotPassword'));
const ChangePassword = lazy(() => import('./changePassword'));
const VerificationCode = lazy(() => import('./verification'));
// const RegsiterSuccess = lazy(() => import('./registerSuccess'));
const ForgotPasswordVerification = lazy(() =>
  import('./forgotPassword/Components/forgotPasswordVerifucation')
);

const AuthRoute = () => {
  let { path } = useRouteMatch();

  return (
    <div>
      <PublicHeader />
      <Switch>
        <Redirect exact from={path} to={`${path}/login`} />
        <Route path={`${path}/login`} component={Login} />
        <Suspense
          fallback={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className='mt-12'>
                <Loader width={40} height={40} color='#6600C9' />
              </div>
            </div>
          }
        >
          <Switch>
            {/* <Route path={`${path}/register`} component={Register} /> */}
            <Route path={`${path}/forgotPassword`} component={ForgotPassword} />

            <Route path={`${path}/changePassword`} component={ChangePassword} />

            <Route path={`${path}/verification`} component={VerificationCode} />
            <Route
              path={`${path}/forgot-password/verification`}
              component={ForgotPasswordVerification}
            />
          </Switch>
        </Suspense>
      </Switch>
    </div>
  );
};
export default AuthRoute;
