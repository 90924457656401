import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import LottiLoader from 'components/LottiLoader';
import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { ErrorToast, SuccessToast } from 'services/toasterService';
import { Dialog } from 'primereact/dialog';
// import Accept from './accept';
// import Decline from './decline';
import { ReactComponent as Close } from 'assets/images/hiringmanager/closeicon.svg';
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import PrimaryButton from 'components/Button/primaryButton';
import { InputTextarea } from 'primereact/inputtextarea';

import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckDarkIcon } from 'assets/icons/CheckDarkIcon.svg';
import { ReactComponent as CheckLightIcon } from 'assets/icons/CheckLightIcon.svg';
import { ReactComponent as InfoIllusLight } from 'assets/icons/InfoIllusLight.svg';
import { Calendar } from 'primereact/calendar';
import { Chips } from 'primereact/chips';
import { Dropdown } from 'primereact/dropdown';

function SendQuotationFormDialog({ isVisible, setVisible, customerData }) {
  const { t } = useTranslation(['quotation', 'common']);
  const currentLanguageCode = cookies.get('i18next');
  const [bccEmails, setBccEmails] = useState([]);

  const customChip = (item) => {
    return (
      <div className=''>
        <span>{item}</span>
      </div>
    );
  };

  const header = (
    <div
      className='flex justify-between items-center cursor-pointer'
      onClick={() => setVisible(false)}
    >
      <div className='w-5 h-5'></div>
      <div>
        <h3 className='text-primary-rose text-xl font-semibold'>
          {t('quotation:sending_quotation')}
        </h3>
      </div>
      <Close />
    </div>
  );
  const queryClient = useQueryClient();

  const defaultValues = {
    q_request_id: '',
    plan_id: '',
    plan_name: '',
    company_name: '',
    country_code: '',
    country_name: '',
    contact_number: '',
    email: '',
    name: '',
    location: '',
    address: '',
    commercial_rnumber: '',
    vat_number: '',
    vat_percentage: '',
    vat_value: '',
    message: '',
    job_listings: '0',
    job_applications: '0',
    psy_assessments: '0',
    video_interviews: '0',
    team_members: '0',
    clients: '0',
    clients_team: '0',
    custom_csite: '',
    job_board_integration: '',
    job_boards: '',
    plan_value: '0',
    is_monthly: false,
    total_value: '0',
    discount_percentage: '',
    discount_value: '',
    due_date: '',
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
  } = useForm({ defaultValues });

  const updateQuotationMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.put(
        apiEndPoints.QUOTATION_REQUEST,
        data
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('newRequests');
        queryClient.invalidateQueries('InprogressRequests');
        queryClient.invalidateQueries('confirmedRequests');
        queryClient.invalidateQueries('DeletedRequests');
        SuccessToast({ text: data.message });
        setVisible(false);
      },
      onError: (err) => {
        ErrorToast({ text: err.response.data.message });
      },
    }
  );

  const sendQuotationMudation = useMutation(async (apiData) => {
    const data = await makeAPIRequest.post(
      apiEndPoints.SEND_QUOTATION_REQUEST,

      apiData
    );
    return data;
  });

  const durationOptions = [
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Yearly', value: 'Yearly' },
  ];

  const onSubmit = (data) => {
    const dataForAPI = {
      ...data,
      due_date: dayjs(data.due_date).format(),
      discount_percentage: +data.discount_percentage,
      vat_percentage: +data.vat_percentage,
      bcc_emails: bccEmails,
      is_monthly: customerData?.plan_info?.validity
        ? +customerData?.plan_info?.validity / 86400 < 35
        : data.is_monthly === 'Monthly'
        ? true
        : false,
    };

    dataForAPI['clients'] = dataForAPI?.clients ? +dataForAPI?.clients : 0;
    dataForAPI['clients_team'] = dataForAPI?.clients ? +dataForAPI?.clients : 0;

    sendQuotationMudation.mutate(dataForAPI, {
      onSuccess: (data) => {
        reset({});
        // SuccessToast({ text: data.message });
        updateQuotationMutation.mutate({
          id: customerData.id,
          status: 'Inprogress',
        });
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    });
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className='p-error'>{errors[name].message}</small>
    );
  };

  const CalculatPercentage = (percentage, mainValue) => {
    return Math.round((percentage * mainValue) / 100);
  };

  useEffect(() => {
    reset({
      q_request_id: customerData?.id,
      plan_id: customerData?.plan_id,
      plan_name: customerData?.plan_info?.name ?? '',
      company_name: customerData?.company_name ?? '',
      country_code: customerData?.country_code ?? '',
      country_name: customerData?.country_name ?? '',
      contact_number: customerData?.contact_number ?? '',
      email: customerData?.email ?? '',
      is_monthly: +customerData?.plan_info?.validity / 86400 < 35 ?? '',
      name: customerData?.name ?? '',
      location: '',
      address: customerData?.address ?? '',
      commercial_rnumber: customerData?.commercial_rnumber ?? '',
      vat_number: customerData?.vat_number ?? '',
      vat_percentage: '',
      vat_value: '0',
      message: '',
      job_listings: customerData?.plan_info
        ? customerData?.plan_info.job_postings
        : customerData?.job_listings ?? '0',
      job_applications: customerData?.plan_info
        ? customerData?.plan_info.applicants_count
        : customerData?.job_applications ?? '0',
      psy_assessments: customerData?.plan_info
        ? customerData?.plan_info.psychometric_count
        : customerData?.psy_assessments ?? '0',
      video_interviews: customerData?.plan_info
        ? customerData?.plan_info.interview_count
        : customerData?.video_interviews ?? '0',
      team_members: customerData?.plan_info
        ? customerData?.plan_info.team_count
        : customerData?.team_members ?? '0',
      clients: customerData?.plan_info
        ? customerData?.plan_info.clients_count
        : customerData?.clients ?? '0',
      clients_team: customerData?.plan_info
        ? customerData?.plan_info.clients_team_count
        : customerData?.clients_team ?? '0',
      custom_csite: false,
      job_board_integration: false,
      job_boards: null,
      plan_value: customerData?.plan_info?.amount ?? '0',
      total_value: '0',
      discount_percentage: '',
      discount_value: '0',
      due_date: '',
      for_hr: customerData?.for_hr,
    });
  }, [customerData, reset]);

  const plan_value = watch('plan_value');
  const total_value = watch('total_value');
  const discount_percentage = watch('discount_percentage');
  const discount_value = watch('discount_value');
  const vat_percentage = watch('vat_percentage');
  const vat_value = watch('vat_value');

  useEffect(() => {
    setValue(
      'discount_value',
      CalculatPercentage(discount_percentage, +getValues('plan_value'))
    );
    // eslint-disable-next-line
  }, [discount_percentage, plan_value]);

  useEffect(() => {
    setValue(
      'vat_value',
      CalculatPercentage(
        vat_percentage,
        +getValues('plan_value') - +getValues('discount_value')
      )
    );
    // eslint-disable-next-line
  }, [vat_percentage, discount_value, plan_value]);

  useEffect(() => {
    setValue(
      'total_value',
      +getValues('plan_value') -
        +getValues('discount_value') +
        +getValues('vat_value')
    );
    // eslint-disable-next-line
  }, [vat_value, discount_value, plan_value]);

  // const getIndividualClientData = useQuery(
  //   ['getIndividualClientData', viewClientData],

  //   async () => {
  //     const resData = await makeAPIRequest.get(
  //       `${apiEndPoints.HiringManagerAPI}?id=${viewClientData?.id}`
  //     );

  //     return resData?.data;
  //   },
  //   { enabled: !!viewClientData }
  // );

  // //   {
  // //     "company_id": 422,
  // //     "active": true
  // // }

  // const updateQuotationMutation = useMutation(
  //   async (data) => {
  //     const res = await makeAPIRequest.post(
  //       apiEndPoints.QUOTATION_REQUEST,
  //       data
  //     );
  //     return res.data;
  //   },
  //   {
  //     onSuccess: (data) => {
  //       queryClient.invalidateQueries('newRequests');
  //       SuccessToast({ text: data.message });
  //     },
  //     onError: (err) => {
  //       ErrorToast({ text: err.response.data.message });
  //     },
  //   }
  // );

  return (
    <>
      <div>
        <Dialog
          header={header}
          visible={isVisible}
          onHide={() => setVisible(false)}
          style={{
            // marginTop: '4rem',
            borderRadius: '1rem',
          }}
          className='w-10/12 max-w-4xl  h-80vh xl:80vh xl:h-90vh  rounded-lg'
          modal
          draggable={false}
          dismissableMask={true}
          blockScroll={true}
          closable={false}
          position='center'
          resizable={false}
        >
          <>
            {false ? (
              <div className='flex justify-center items-center w-full h-full'>
                <LottiLoader />
              </div>
            ) : (
              <>
                <form className='h-full' onSubmit={handleSubmit(onSubmit)}>
                  <div className='w-full h-full flex flex-col items-center'>
                    <div className='flex flex-col w-full max-w-2xl py-4'>
                      <div className='w-full my-4'>
                        <span className='p-float-label'>
                          <Controller
                            name='company_name'
                            control={control}
                            rules={{
                              required: t('common:this_is_a_required_field'),
                            }}
                            render={({ field }) => (
                              <InputText
                                readOnly
                                className=''
                                id={field.name}
                                {...field}
                              />
                            )}
                          />
                          <label htmlFor='company_name'>
                            {t('quotation:bill_to')} ( {t('quotation:org_name')}
                            )*
                          </label>
                        </span>
                        {getFormErrorMessage('company_name')}
                      </div>
                      <div className='flex'>
                        <div
                          className={`w-1/2 ${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        >
                          <div className='w-full my-4'>
                            <span className='p-float-label'>
                              <Controller
                                name='location'
                                control={control}
                                rules={{
                                  required: t(
                                    'common:this_is_a_required_field'
                                  ),
                                }}
                                render={({ field }) => (
                                  <InputText
                                    className=''
                                    id={field.name}
                                    {...field}
                                  />
                                )}
                              />
                              <label htmlFor='location'>
                                {t('common:location')} *
                              </label>
                            </span>
                            {getFormErrorMessage('location')}
                          </div>
                        </div>
                        <div
                          className={`w-1/2 ${
                            currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                          }`}
                        >
                          <div className='w-full my-4'>
                            <span className='p-float-label'>
                              <Controller
                                name='vat_percentage'
                                control={control}
                                rules={{
                                  pattern: {
                                    value: /^[0-9]*$/i,
                                    message: t(
                                      'common:please_provide_only_numbers'
                                    ),
                                  },
                                  required: t(
                                    'common:this_is_a_required_field'
                                  ),
                                }}
                                render={({ field }) => (
                                  <InputText
                                    type={'number'}
                                    onWheel={() =>
                                      document.activeElement.blur()
                                    }
                                    className=''
                                    id={field.name}
                                    {...field}
                                    onChange={(e) => {
                                      if (
                                        e.target.value >= 0 &&
                                        e.target.value <= 100
                                      ) {
                                        field.onChange(e);
                                      }
                                    }}
                                  />
                                )}
                              />
                              <label htmlFor='vat_percentage'>
                                {t('quotation:applicable_vat')} (%) *
                              </label>
                            </span>
                            {getFormErrorMessage('vat_percentage')}
                          </div>
                        </div>
                      </div>

                      <div className='w-full my-4'>
                        <span className='p-float-label'>
                          <Controller
                            name='address'
                            control={control}
                            rules={{
                              required: t('common:this_is_a_required_field'),
                            }}
                            render={({ field }) => (
                              <InputTextarea
                                className=''
                                id={field.name}
                                {...field}
                                autoResize={false}
                                rows={3}
                              />
                            )}
                          />
                          <label htmlFor='address'>
                            {t('common:address')}*
                          </label>
                        </span>
                        {getFormErrorMessage('address')}
                      </div>
                      <div className='flex'>
                        <div
                          className={`w-1/2 ${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        >
                          <div className='w-full my-4'>
                            <span className='p-float-label'>
                              <Controller
                                name='commercial_rnumber'
                                control={control}
                                rules={{
                                  required: t(
                                    'common:this_is_a_required_field'
                                  ),
                                }}
                                render={({ field }) => (
                                  <InputText
                                    className=''
                                    id={field.name}
                                    {...field}
                                  />
                                )}
                              />
                              <label htmlFor='commercial_rnumber'>
                                {t('quotation:cr_no')}. *
                              </label>
                            </span>
                            {getFormErrorMessage('commercial_rnumber')}
                          </div>
                        </div>
                        <div
                          className={`w-1/2 ${
                            currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                          }`}
                        >
                          <div className='w-full my-4'>
                            <span className='p-float-label'>
                              <Controller
                                name='vat_number'
                                control={control}
                                rules={{
                                  required: t(
                                    'common:this_is_a_required_field'
                                  ),
                                }}
                                render={({ field }) => (
                                  <InputText
                                    className=''
                                    id={field.name}
                                    {...field}
                                  />
                                )}
                              />
                              <label htmlFor='vat_number'>
                                {t('quotation:vat_no')}. *
                              </label>
                            </span>
                            {getFormErrorMessage('vat_number')}
                          </div>
                        </div>
                      </div>
                      <div className='w-full my-4'>
                        <span className='p-float-label'>
                          <Controller
                            name='message'
                            control={control}
                            // rules={{
                            //   required: t('common:this_is_a_required_field'),
                            // }}
                            render={({ field }) => (
                              <InputTextarea
                                className=''
                                id={field.name}
                                {...field}
                                autoResize={false}
                                rows={3}
                              />
                            )}
                          />
                          <label htmlFor='message'>
                            {t('quotation:add_a_message')}
                          </label>
                        </span>
                        {getFormErrorMessage('message')}
                      </div>
                      <div className='bg-FFF7F5 flex w-full flex-col p-4'>
                        <p className='text-lg font-semibold text-primary-rose mb-1'>
                          {t('common:plan_details')}
                        </p>
                        <div>
                          <div className='flex flex-col w-full justify-center my-4'>
                            {customerData?.plan_info ? (
                              <div className='flex items-center justify-between border-b border-rose border-opacity-60 py-2'>
                                <p className='text-2xl font-semibold '>
                                  {customerData?.plan_info.name}
                                </p>
                                <div className='flex items-center'>
                                  <p
                                    className={`text-2xl font-semibold ${
                                      currentLanguageCode === 'ar'
                                        ? 'ml-2'
                                        : 'mr-2'
                                    }`}
                                  >
                                    SR {customerData?.plan_info.amount ?? '-'}
                                  </p>
                                  <span className='text-848199'>
                                    /{' '}
                                    {
                                      <span className='text-848199 font-normal text-md '>
                                        {customerData?.plan_info.validity?.split(
                                          '.'
                                        )[0] / 86400 ?? '-'}{' '}
                                        {t('common:days')}
                                      </span>
                                    }
                                    , {t('common:billed_annually')}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className='flex items-center justify-between border-b border-rose border-opacity-60 py-2'>
                                <p className='text-2xl font-semibold '>
                                  {t('common:custom_plan')}
                                </p>
                              </div>
                            )}
                            <div className='flex flex-col mt-6'>
                              <div className='flex justify-between items-center my-2'>
                                <p className=''>
                                  {t('quotation:job_listings')}
                                </p>
                                <div className='w-32 my-4'>
                                  <span className='p-float-label'>
                                    <Controller
                                      name='job_listings'
                                      control={control}
                                      rules={{
                                        required: t(
                                          'common:this_is_a_required_field'
                                        ),
                                        pattern: {
                                          value: /^[0-9]*$/i,
                                          message: t(
                                            'common:please_provide_only_numbers'
                                          ),
                                        },
                                      }}
                                      render={({ field }) => (
                                        <InputText
                                          type={'number'}
                                          onWheel={() =>
                                            document.activeElement.blur()
                                          }
                                          id={field.name}
                                          {...field}
                                        />
                                      )}
                                    />
                                    <label htmlFor='job_listings'>
                                      {t('quotation:qty')} *
                                    </label>
                                  </span>
                                  {getFormErrorMessage('job_listings')}
                                </div>
                              </div>
                              <div className='flex justify-between items-center my-2'>
                                <p className=''>
                                  {t('quotation:job_applications')} (
                                  {t('quotation:per_job')})
                                </p>
                                <div className='w-32 my-4'>
                                  <span className='p-float-label'>
                                    <Controller
                                      name='job_applications'
                                      control={control}
                                      rules={{
                                        required: t(
                                          'common:this_is_a_required_field'
                                        ),
                                        pattern: {
                                          value: /^[0-9]*$/i,
                                          message: t(
                                            'common:please_provide_only_numbers'
                                          ),
                                        },
                                      }}
                                      render={({ field }) => (
                                        <InputText
                                          type={'number'}
                                          onWheel={() =>
                                            document.activeElement.blur()
                                          }
                                          id={field.name}
                                          {...field}
                                        />
                                      )}
                                    />
                                    <label htmlFor='job_applications'>
                                      {t('quotation:qty')} *
                                    </label>
                                  </span>
                                  {getFormErrorMessage('job_applications')}
                                </div>
                              </div>
                              <div className='flex justify-between items-center my-2'>
                                <p className=''>
                                  {t('quotation:ai_psychometric_assessments')} (
                                  {t('quotation:per_job')})
                                </p>
                                <div className='w-32 my-4'>
                                  <span className='p-float-label'>
                                    <Controller
                                      name='psy_assessments'
                                      control={control}
                                      rules={{
                                        required: t(
                                          'common:this_is_a_required_field'
                                        ),
                                        pattern: {
                                          value: /^[0-9]*$/i,
                                          message: t(
                                            'common:please_provide_only_numbers'
                                          ),
                                        },
                                      }}
                                      render={({ field }) => (
                                        <InputText
                                          type={'number'}
                                          onWheel={() =>
                                            document.activeElement.blur()
                                          }
                                          id={field.name}
                                          {...field}
                                        />
                                      )}
                                    />
                                    <label htmlFor='psy_assessments'>
                                      {t('quotation:qty')} *
                                    </label>
                                  </span>
                                  {getFormErrorMessage('psy_assessments')}
                                </div>
                              </div>
                              <div className='flex justify-between items-center my-2'>
                                <p className=''>
                                  {t('quotation:integrated_video_interviews')}
                                </p>
                                <p className='text-primary-rose font-semibold'>
                                  {customerData?.plan_info?.interview_count ===
                                    -1 || customerData?.video_interviews === -1
                                    ? 'Unlimited'
                                    : (
                                        <div className='w-32 my-4'>
                                          <span className='p-float-label'>
                                            <Controller
                                              name='video_interviews'
                                              control={control}
                                              rules={{
                                                required: t(
                                                  'common:this_is_a_required_field'
                                                ),
                                                pattern: {
                                                  value: /^[0-9]*$/i,
                                                  message: t(
                                                    'common:please_provide_only_numbers'
                                                  ),
                                                },
                                              }}
                                              render={({ field }) => (
                                                <InputText
                                                  type={'number'}
                                                  onWheel={() =>
                                                    document.activeElement.blur()
                                                  }
                                                  id={field.name}
                                                  {...field}
                                                />
                                              )}
                                            />
                                            <label htmlFor='video_interviews'>
                                              {t('quotation:qty')}*
                                            </label>
                                          </span>
                                          {getFormErrorMessage(
                                            'video_interviews'
                                          )}
                                        </div>
                                      ) ?? '-'}
                                </p>
                              </div>
                              <div className='flex justify-between items-center my-2'>
                                <p className=''>
                                  {t('quotation:team_members')}
                                </p>
                                <div className='w-32 my-4'>
                                  <span className='p-float-label'>
                                    <Controller
                                      name='team_members'
                                      control={control}
                                      rules={{
                                        required: t(
                                          'common:this_is_a_required_field'
                                        ),
                                        pattern: {
                                          value: /^[0-9]*$/i,
                                          message: t(
                                            'common:please_provide_only_numbers'
                                          ),
                                        },
                                      }}
                                      render={({ field }) => (
                                        <InputText
                                          type={'number'}
                                          onWheel={() =>
                                            document.activeElement.blur()
                                          }
                                          id={field.name}
                                          {...field}
                                        />
                                      )}
                                    />
                                    <label htmlFor='team_members'>
                                      {t('quotation:qty')} *
                                    </label>
                                  </span>
                                  {getFormErrorMessage('team_members')}
                                </div>
                              </div>
                              {!customerData?.for_hr ? (
                                <div className='flex justify-between items-center my-2'>
                                  <p className=''>{t('common:clients')}</p>
                                  <div className='w-32 my-4'>
                                    <span className='p-float-label'>
                                      <Controller
                                        name='clients'
                                        control={control}
                                        rules={{
                                          required: t(
                                            'common:this_is_a_required_field'
                                          ),
                                          pattern: {
                                            value: /^[0-9]*$/i,
                                            message: t(
                                              'common:please_provide_only_numbers'
                                            ),
                                          },
                                        }}
                                        render={({ field }) => (
                                          <InputText
                                            type={'number'}
                                            onWheel={() =>
                                              document.activeElement.blur()
                                            }
                                            id={field.name}
                                            {...field}
                                          />
                                        )}
                                      />
                                      <label htmlFor='clients'>
                                        {t('quotation:qty')} *
                                      </label>
                                    </span>
                                    {getFormErrorMessage('clients')}
                                  </div>
                                </div>
                              ) : null}
                              {!customerData?.for_hr ? (
                                <div className='flex justify-between items-center my-2'>
                                  <p className=''>
                                    {t('quotation:client_team_members')}
                                  </p>
                                  <div className='w-32 my-4'>
                                    <span className='p-float-label'>
                                      <Controller
                                        name='clients_team'
                                        control={control}
                                        rules={{
                                          required: t(
                                            'common:this_is_a_required_field'
                                          ),
                                          pattern: {
                                            value: /^[0-9]*$/i,
                                            message: t(
                                              'common:please_provide_only_numbers'
                                            ),
                                          },
                                        }}
                                        render={({ field }) => (
                                          <InputText
                                            type={'number'}
                                            onWheel={() =>
                                              document.activeElement.blur()
                                            }
                                            id={field.name}
                                            {...field}
                                          />
                                        )}
                                      />
                                      <label htmlFor='clients_team'>
                                        {t('quotation:qty')} *
                                      </label>
                                    </span>
                                    {getFormErrorMessage('clients_team')}
                                  </div>
                                </div>
                              ) : null}
                              <div className='flex justify-between my-2'>
                                <p className=''>{t('quotation:support')}</p>
                                <p className='text-primary-rose font-semibold'>
                                  {t('quotation:included')}
                                </p>
                              </div>
                              <div className='flex flex-col my-2'>
                                <div className='flex justify-between my-2'>
                                  <p className='text-9B9B9B'>
                                    {t('common:email')}
                                  </p>
                                  <span>
                                    <CheckDarkIcon />
                                  </span>
                                </div>
                                <div className='flex justify-between my-2'>
                                  <p className='text-9B9B9B'>
                                    {t('quotation:chat')}
                                  </p>
                                  <span>
                                    <CheckLightIcon />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className='flex flex-col mt-6'>
                              <p className='font-bold'>
                                {t('quotation:additional_features')}
                              </p>
                              <div className='flex flex-col my-2'>
                                <div className='flex justify-between my-2'>
                                  <p className='text-9B9B9B'>
                                    {t('quotation:custom_career_site')}
                                  </p>
                                  <span>
                                    <CheckDarkIcon />
                                  </span>
                                </div>
                                <div className='flex justify-between my-2'>
                                  <div className='flex items-center'>
                                    <p className='text-9B9B9B'>
                                      {t('quotation:job_board_integration')}
                                    </p>
                                    <span
                                      className={`${
                                        currentLanguageCode === 'ar'
                                          ? 'mr-2'
                                          : 'ml-2'
                                      }`}
                                    >
                                      <InfoIllusLight />
                                    </span>
                                  </div>
                                  <span>
                                    <CheckLightIcon />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className='flex justify-end items-center my-2'>
                            <p
                              className={`font-semibold text-lg ${
                                currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                              }`}
                            >
                              {t('quotation:total')}
                            </p>
                            <div className='w-32 my-4'>
                              <span className='p-float-label'>
                                <Controller
                                  name='plan_value'
                                  control={control}
                                  rules={{
                                    required: t(
                                      'common:this_is_a_required_field'
                                    ),
                                    pattern: {
                                      value: /^[0-9]*$/i,
                                      message: t(
                                        'common:please_provide_only_numbers'
                                      ),
                                    },
                                  }}
                                  render={({ field }) => (
                                    <InputText
                                      className='text-primary-rose'
                                      type={'number'}
                                      onWheel={() =>
                                        document.activeElement.blur()
                                      }
                                      id={field.name}
                                      {...field}
                                    />
                                  )}
                                />
                                <label htmlFor='plan_value'>
                                  {t('quotation:amount')} (SR) *
                                </label>
                              </span>
                              {getFormErrorMessage('plan_value')}
                            </div>
                          </div>
                          {customerData?.plan_info?.validity ? null : (
                            <div className='flex justify-end items-center my-2'>
                              <p
                                className={`${
                                  currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                                }`}
                              >
                                {t('quotation:plan_duration')}
                              </p>
                              <div className='w-32 my-4'>
                                <span className='p-float-label'>
                                  <Controller
                                    name='is_monthly'
                                    control={control}
                                    rules={{
                                      required: t(
                                        'common:this_is_a_required_field'
                                      ),
                                    }}
                                    render={({ field }) => (
                                      <Dropdown
                                        id={field.name}
                                        {...field}
                                        options={durationOptions}
                                        optionLabel='label'
                                      />
                                    )}
                                  />
                                </span>
                                {getFormErrorMessage('is_monthly')}
                              </div>
                            </div>
                          )}

                          <div className='flex justify-end items-center my-2'>
                            <p
                              className={`text-sm ${
                                currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                              }`}
                            >
                              {t('quotation:vat')}
                            </p>
                            <div className='w-32 text-sm'>SR {vat_value}</div>
                          </div>
                          <div className='flex justify-end items-center my-2'>
                            <p
                              className={`text-sm ${
                                currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                              }`}
                            >
                              {t('quotation:discount')}
                            </p>
                            <div className='w-32 text-sm'>
                              SR {discount_value}
                            </div>
                          </div>
                          <div className='flex justify-end items-center my-2'>
                            <p
                              className={`font-semibold text-lg ${
                                currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                              }`}
                            >
                              {t('quotation:net_total')}
                            </p>
                            <div className='w-32 my-4 font-semibold text-primary-rose'>
                              SR {total_value}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex mt-6'>
                        <div
                          className={`w-1/2 ${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        >
                          <div className='w-full my-4'>
                            <span className='p-float-label'>
                              <Controller
                                name='discount_percentage'
                                control={control}
                                rules={{
                                  pattern: {
                                    value: /^[0-9]*$/i,
                                    message: t(
                                      'common:please_provide_only_numbers'
                                    ),
                                  },
                                }}
                                render={({ field }) => (
                                  <InputText
                                    className=''
                                    type={'number'}
                                    onWheel={() =>
                                      document.activeElement.blur()
                                    }
                                    id={field.name}
                                    {...field}
                                    onChange={(e) => {
                                      if (
                                        e.target.value >= 0 &&
                                        e.target.value <= 100
                                      ) {
                                        field.onChange(e);
                                      }
                                    }}
                                  />
                                )}
                              />
                              <label htmlFor='discount_percentage'>
                                {t('quotation:discount')} (%)
                              </label>
                            </span>
                            {getFormErrorMessage('discount_percentage')}
                          </div>
                        </div>
                        <div
                          className={`w-1/2 ${
                            currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                          }`}
                        >
                          <div className='w-full my-4'>
                            <span className='p-float-label'>
                              <Controller
                                name='due_date'
                                control={control}
                                rules={{
                                  required: t(
                                    'common:this_is_a_required_field'
                                  ),
                                }}
                                render={({ field }) => (
                                  <Calendar
                                    id={field.name}
                                    dateFormat='dd-mm-yy'
                                    className='w-full '
                                    {...field}
                                  />
                                )}
                              />
                              <label htmlFor='due_date'>
                                {t('quotation:due_date')}*
                              </label>
                            </span>
                            {getFormErrorMessage('due_date')}
                          </div>
                        </div>
                      </div>
                      <div className='my-4 mt-6'>
                        <div className='my-2 w-full'>
                          <Chips
                            value={bccEmails}
                            onChange={(e) => {
                              setBccEmails(e.value);
                            }}
                            style={{ width: '100%' }}
                            itemTemplate={customChip}
                            allowDuplicate={false}
                            separator=','
                            placeholder='Add email ID and press enter'
                          />
                        </div>
                        <div>
                          <p className='text-BDBDBD text-sm'>
                            {t('quotation:note')} :{' '}
                            {t(
                              'quotation:these_mail_ids_will_be_added_as_blind_carbon_copy'
                            )}
                            .
                          </p>
                        </div>
                      </div>
                    </div>
                    <button className='w-40 lg:w-44 my-6 pb-20 '>
                      <PrimaryButton
                        isLoading={
                          sendQuotationMudation.isLoading ||
                          updateQuotationMutation.isLoading
                        }
                      >
                        <p className='w-full text-sm lg:text-base'>
                          {t('common:submit')}
                        </p>
                      </PrimaryButton>
                    </button>
                  </div>
                </form>
              </>
            )}
          </>
        </Dialog>
      </div>
    </>
  );
}

export default SendQuotationFormDialog;
