import React from 'react';
// import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import cookies from 'js-cookie';

import { ReactComponent as Logo } from 'assets/images/logo.svg';

const PublicHeader = () => {
  // const history = useHistory();
  const currentLanguageCode = cookies.get('i18next');
  // const { t } = useTranslation();

  return (
    <div className='sticky top-0 z-50  px-2 sm:px-4 py-2 h-20 bg-white shadow-md '>
      <div className='flex md:justify-between px-4 2xl:px-1 py-2 max-w-main-page mx-auto items-center'>
        <div className='sm:1/12 lg:w-2/12'>
          <a href='https://smart-hire.ai/'>
            <Logo className='w-32 h-20 pb-6' />
          </a>
        </div>

        <div className='9/12 lg:w-8/12 flex justify-center items-center lg:text-lg'></div>
        <div className='w-2/12 justify-end items-center hidden sm:flex flex-col'>
          {/* {history.location.pathname.includes('client') ? null : (
            <>
              {history.location.pathname.includes('register') ? (
                <a
                  href='https://app.apta-hr.com/auth/login'
                  className='text-sm text-center  whitespace-nowrap font-medium  bg-primary-rose text-white  px-2 py-2 rounded-md leading-5 hover:bg-primary-rose-dark transition-colors'
                  style={{ width: '100px' }}
                >
                  Sign in
                </a>
              ) : (
                <a
                  href='https://app.apta-hr.com/auth/register'
                  className='text-sm text-center  whitespace-nowrap font-medium  bg-primary-rose text-white  px-2 py-2 rounded-md leading-5 hover:bg-primary-rose-dark transition-colors'
                  style={{ width: '100px' }}
                >
                  Sign up
                </a>
              )}
            </>
          )} */}
          <div
            className={`${
              currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
            } mb-4 flex items-center `}
          >
            <button
              type='button'
              onClick={() => {
                i18next.changeLanguage('en');
                localStorage.setItem('dir', JSON.stringify('ltr'));
              }}
              className={`${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
            >
              <p
                className={`${
                  currentLanguageCode === 'en'
                    ? 'text-purple mr-4'
                    : 'text-custome_gray ml-4'
                } `}
              >
                En
              </p>
            </button>
            <button
              className={`flex items-start mb-1`}
              type='button'
              onClick={() => {
                i18next.changeLanguage('ar');
                localStorage.setItem('dir', JSON.stringify('rtl'));
              }}
            >
              <p
                className={`w-full rounded text-xl mt-1 ${
                  currentLanguageCode === 'ar'
                    ? 'text-purple'
                    : 'text-custome_gray'
                } `}
              >
                العربية
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicHeader;
