import React, { Suspense } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { lazy } from '@loadable/component';
import LottiLoader from 'components/LottiLoader';
import NoPageFound from 'pages/common/error/noPageFound';
import BasePrivateLayout from '../../basePrivateLayout';

const DemoView = lazy(() => import('./demoPage.jsx'));
const DemoFullView = lazy(() => import('./demoFullView'));

function Demo() {
  const { path } = useRouteMatch();

  return (
    <BasePrivateLayout>
      <Suspense
        fallback={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='max-w-main-page h-70vh flex justify-center items-center '>
              <LottiLoader />
            </div>
          </div>
        }
      >
        <Switch>
          <Redirect exact from={path} to={`${path}/demoview`} />
          <Route path={`${path}/demoview`} component={DemoView} />
          <Route path={`${path}/all`} component={DemoFullView} />
          {/* <Route path={`${path}/singleclient/:id`} component={SingleClient} /> */}

          {/* DO NOT CHNAGE this empty route position Add everything above this line */}
          <Route component={NoPageFound} />
        </Switch>
      </Suspense>
    </BasePrivateLayout>
  );
}

export default Demo;
