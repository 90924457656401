import React from 'react';
import Lottie from 'react-lottie-player';

import loaderLottie from 'assets/lottiefiles/loder_lottie2.json';

const LottiLoader = ({ width = '70px', height = '70px' }) => {
  return (
    <Lottie
      animationData={loaderLottie}
      background='transparent'
      speed='1'
      style={{ width: width, height: height }}
      loop
      play
    />
  );
};

export default LottiLoader;
