import { useState } from 'react';

import { useTranslation } from 'react-i18next';

const SkillTypeSwitch = ({
  keyName,
  setSkillData,
  skillData,
  currentSkillData,
}) => {
  const { t } = useTranslation(['common']);

  const [ishardSkill, setIshardSkill] = useState(
    currentSkillData?.is_hard_skill
  );

  const funv = (val) => {
    let items = skillData.map((x) =>
      x[keyName] === currentSkillData[keyName]
        ? { ...currentSkillData, is_hard_skill: val }
        : x
    );
    setSkillData(items);
    console.log(items);
  };

  return (
    <div className=' w-max'>
      <ul className='grid grid-cols-2 w-max'>
        <li
          className={`py-1.5 cursor-pointer transition-colors w-28 text-center rounded-tl-lg rounded-bl-lg ${
            ishardSkill
              ? 'text-purple cursor-pointer border-3 border-purple font-bold'
              : 'text-gray-500 border'
          }`}
          onClick={() => {
            funv(true);
            setIshardSkill(true);
          }}
        >
          {t('common:hard_skill')}
        </li>
        <li
          onClick={() => {
            funv(false);
            setIshardSkill(false);
          }}
          className={`py-1.5 cursor-pointer transition-colors w-28 text-center rounded-tr-lg rounded-br-lg ${
            !ishardSkill
              ? 'text-purple cursor-pointer border-3 border-purple font-bold'
              : 'text-gray-500 border'
          }`}
        >
          {t('common:soft_skill')}
        </li>
      </ul>
    </div>
  );
};

export default SkillTypeSwitch;
