import { useState } from 'react';
import { useMutation } from 'react-query';

import BasePrivateLayout from '../../basePrivateLayout';

import PrimaryButton from 'components/Button/primaryButton';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import BulkUpload from './components/bulkUploadModal';
import AddSkillModal from './components/addSkillWordModal';
import SkillTypeSwitch from './components/skillTypeSwitch';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { SuccessToast, ErrorToast } from 'services/toasterService';

import { ReactComponent as AddIcon } from 'assets/images/skillTraining/addIcon.svg';
import { ReactComponent as RemoveIcon } from 'assets/images/skillTraining/RemoveIcon.svg';
import { ReactComponent as BulkUploadIllustration } from 'assets/images/assessmentBank/BukuploadIllustration.svg';

const SkillTraningPage = () => {
  const { t } = useTranslation(['common']);
  const currentLanguageCode = cookies.get('i18next');
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const [showAddSkillModal, setShowAddSkillModal] = useState(false);

  const [skillData, setSkillData] = useState([]);

  const handleSelectedValue = (data, indexValue) => {
    const newProjects = skillData.map((item, index) =>
      index === indexValue
        ? { ...item, selectedValue: item.selectedValue === data ? '' : data }
        : item
    );
    setSkillData(newProjects);
  };

  const trainSkillWordMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(
        apiEndPoints.SKILL_TRANING_API,
        data
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        SuccessToast({ text: data.message });
        setSkillData([]);
      },
      onError: (error) => {
        if (error.response.data.message === 'Something Went Wrong') {
          ErrorToast({ text: error.response.data.data });
        } else {
          ErrorToast({ text: error.response.data.message });
        }
      },
    }
  );

  const handelSkilltraning = () => {
    let items = skillData.map((skill) => {
      return {
        word: Object.keys(skill)?.[0],
        similar_word: skill.selectedValue,
        is_hard_skill: skill.is_hard_skill,
      };
    });
    trainSkillWordMutation.mutate({ words: items });
  };

  return (
    <>
      <BasePrivateLayout>
        <div className='max-w-main-page xl:mx-auto mx-4 px-6 pt-8 pb-10'>
          <section className='flex justify-between items-center mb-8'>
            <p className=' text-black-1 text-xl font-bold'>
              {t('common:skill_words_training')}
            </p>
            <div className=' flex'>
              <div
                onClick={() => setShowBulkUploadModal(true)}
                className={`cursor-pointer ${
                  currentLanguageCode === 'ar' ? 'ml-8' : 'mr-8'
                }`}
              >
                <BulkUploadIllustration
                  className={`p-0.5 ${
                    currentLanguageCode === 'ar' ? 'mr-3' : 'ml-3'
                  }`}
                />
                <p className='text-sm text-Neutrals-gray text-center'>
                  {t('common:bulk_upload')}
                </p>
              </div>

              <button
                className={`w-32 opacity-70 cursor-not-allowed transition-none ${
                  currentLanguageCode === 'ar' ? 'ml-8' : 'mr-8'
                }`}
                onClick={() => setShowAddSkillModal(true)}
                disabled
              >
                <PrimaryButton>+ {t('common:add_a_skill')}</PrimaryButton>
              </button>
              <button
                className={`w-32 ${
                  currentLanguageCode === 'ar' ? 'ml-8' : 'mr-8'
                }`}
                onClick={handelSkilltraning}
                disabled={trainSkillWordMutation.isLoading}
              >
                <PrimaryButton isLoading={trainSkillWordMutation.isLoading}>
                  {t('common:train')}
                </PrimaryButton>
              </button>
            </div>
          </section>

          <section>
            <div className='w-full bg-white shadow-xl rounded border py-2 px-8'>
              <div className='grid grid-cols-8 py-2 '>
                <p className='col-span-2 text-primary-rose font-bold text-lg px-2'>
                  {t('common:skill')}
                </p>
                <p className='col-span-4 text-primary-rose font-bold text-lg px-8'>
                  {t('common:similar_skill')}
                </p>
                <p className='col-span-2 text-primary-rose font-bold text-lg px-2'>
                  {t('common:type_of_skill')}
                </p>
              </div>
              <div className=' h-px w-full bg-gray-200 mb-1'></div>
              <div className=' h-50vh xl:h-55vh 2xl:h-57vh overflow-y-auto scroller py-1'>
                {skillData.map((item, index) => {
                  return (
                    <div
                      className='grid grid-cols-8 h-28'
                      key={Object.keys(item)?.[0]}
                    >
                      <div className='col-span-2 text-base px-2 flex items-center'>
                        <p className='text-lg text-text-neutrals-black'>
                          {Object.keys(item)?.[0]}
                        </p>
                      </div>
                      <div className='col-span-4 flex items-center '>
                        <div className='py-4 px-4 bg-F4F4F4 w-full mx-6 my-6 rounded-lg flex flex-wrap overflow-y-scroll scroller select-none h-24'>
                          {Array.from(new Set(Object.values(item)?.[0])).map(
                            (value) => {
                              if (Object.values(item)?.[1] === value) {
                                return (
                                  <div
                                    className='bg-EAEAEA px-2.5 py-1 mx-1 mb-3 h-8 flex items-center justify-between rounded-md cursor-pointer border-2 border-primary-rose'
                                    key={value}
                                    onClick={() =>
                                      handleSelectedValue(value, index)
                                    }
                                  >
                                    <p className='text-neutrals-black'>
                                      {value}
                                    </p>
                                    <RemoveIcon
                                      className={`w-4 ${
                                        currentLanguageCode === 'ar'
                                          ? 'mr-2'
                                          : 'ml-2'
                                      }`}
                                    />
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    className='bg-EAEAEA px-2.5 py-1 mx-1 mb-3 h-8 border-2 flex items-center justify-between rounded-md cursor-pointer'
                                    key={value}
                                    onClick={() =>
                                      handleSelectedValue(value, index)
                                    }
                                  >
                                    <p className='text-neutrals-black'>
                                      {value}
                                    </p>
                                    <AddIcon
                                      className={`w-4 ${
                                        currentLanguageCode === 'ar'
                                          ? 'mr-2'
                                          : 'ml-2'
                                      }`}
                                    />
                                  </div>
                                );
                              }
                            }
                          )}
                        </div>
                      </div>
                      <div className='col-span-2 px-4 flex items-center'>
                        <SkillTypeSwitch
                          keyName={Object.keys(item)?.[0]}
                          setSkillData={setSkillData}
                          skillData={skillData}
                          currentSkillData={item}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </div>
      </BasePrivateLayout>
      <BulkUpload
        isVisible={showBulkUploadModal}
        setVisible={setShowBulkUploadModal}
        setSkillData={setSkillData}
      />
      <AddSkillModal
        isVisible={showAddSkillModal}
        setVisible={setShowAddSkillModal}
      />
    </>
  );
};

export default SkillTraningPage;
