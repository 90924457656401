import React from 'react';
// import Loader from 'components/Loader';
import loadable from '@loadable/component';
import { Skeleton } from 'primereact/skeleton';

import { useTranslation } from 'react-i18next';

import { ReactComponent as NoJobsCount } from 'assets/images/dashboard/NoJobsCount.svg';

const JobsDonutChart = loadable(() => import('./charts/JobsDonutChart'), {
  fallback: (
    <div className=' flex flex-1 flex-col'>
      <div className='flex flex-1 justify-center min-h-300px max-h-300px flex-col scroller overflow-y-auto'>
        <div className='flex my-4 justify-center'>
          <Skeleton height='12rem' width='12rem' borderRadius='12rem' />
        </div>
      </div>
    </div>
  ),
});

function JobAnalytics({ getJobsAppCount }) {
  const { t } = useTranslation(['dashboard']);
  return (
    <>
      <div className='w-full flex flex-col  px-6 py-0'>
        {getJobsAppCount.isLoading || getJobsAppCount.isFetching ? (
          <div className=' flex flex-1 flex-col'>
            <div className='flex flex-1 justify-center min-h-300px max-h-300px flex-col scroller overflow-y-auto'>
              <div className='flex my-4 justify-center'>
                <Skeleton height='12rem' width='12rem' borderRadius='12rem' />
              </div>
            </div>
          </div>
        ) : getJobsAppCount?.data?.active_jobs > 0 ||
          getJobsAppCount?.data?.inactive_jobs > 0 ||
          getJobsAppCount?.data?.paused_jobs > 0 ? (
          <div className=' flex flex-1 flex-col justify-between'>
            <JobsDonutChart
              chartData={[
                {
                  name: 'Active Jobs',
                  value: getJobsAppCount?.data?.active_jobs || 0,
                  color: '#FB8861',
                  start: '#F272C1',
                  end: '#EB249E',
                },
                {
                  name: 'Paused Jobs',
                  value: getJobsAppCount?.data?.paused_jobs || 0,
                  color: '#DADADA',
                  start: '#DEDEDE',
                  end: '#DADADA',
                },
                {
                  name: 'Inactive Jobs',
                  value: getJobsAppCount?.data?.inactive_jobs || 0,
                  color: '#DADADA',
                  start: '#DADADA',
                  end: '#515151',
                },
              ]}
              JobData={{
                title: 'Total Jobs',
                value: getJobsAppCount?.data?.total_jobs,
              }}
            />
          </div>
        ) : (
          <div className=' flex justify-center items-center h-72'>
            <div className='flex h-full mt-6 justify-center items-center flex-col'>
              <NoJobsCount />
              <span className='text-Neutrals-gray-1 text-sm mt-6 mb-2'>
                {t('dashboard:no_jobs_to_show_graph')}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default JobAnalytics;
