import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { useDropzone } from 'react-dropzone';
import { Dialog } from 'primereact/dialog';

import PrimaryButton from 'components/Button/primaryButton';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { SuccessToast, ErrorToast } from 'services/toasterService';

import { ReactComponent as DownloadIlls } from 'assets/images/assessmentBank/downloadIlls.svg';

import { ReactComponent as UploadIcon } from 'assets/images/assessmentBank/UploadIcon.svg';

function BulkUpload({ isVisible, setVisible, setSkillData }) {
  const { t } = useTranslation(['common']);
  const currentLanguageCode = cookies.get('i18next');
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState();
  const [noFileError, setNoFileError] = useState('');

  function fileSizeValidator(file) {
    if (file.size > 300000) {
      return {
        code: 'size-too-large',
        message: `* ${t('common:upto_300kb_supported')}`,
      };
    }
    return null;
  }
  const onDrop = useCallback((acceptedFiles) => {
    setFileName(acceptedFiles[0]?.name);
    setNoFileError('');
    if (acceptedFiles.length >= 1) {
      setFile(acceptedFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept:
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    maxFiles: 1,
    validator: fileSizeValidator,
  });

  const fileRejectionItems = (
    <ul>
      {fileRejections
        .map((item) => item.errors[0].message)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((item, index) => (
          <li key={item + index} className='text-xs my-1 text-red-600'>
            {item ===
            'File type must be .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              ? '* Only xlxs and xls supported'
              : item}
          </li>
        ))}
    </ul>
  );

  const onBulkUpload = () => {
    if (file) {
      let formData = new FormData();
      formData.append('excel_file', file);

      bulkUploadMutation.mutate(formData);
    } else {
      setNoFileError('* File required');
    }
  };

  const bulkUploadMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(
        apiEndPoints.SKILL_TRANING_API_UPLOAD,
        data
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        setFileName('');
        setFile();
        setNoFileError('');
        setVisible(false);
        SuccessToast({ text: data.message });

        /*   {
          react: ['reactjs', 'react-js', 'react', 'react js'],
          selectedValue: '',
        }, */

        let tempSkillArr = [];

        for (const [key, value] of Object.entries(data.data)) {
          tempSkillArr.push({
            [key]: [...value],
            selectedValue: '',
            is_hard_skill: true,
          });
        }
        setSkillData(tempSkillArr);
      },
      onError: (error) => {
        if (error.response.data.message === 'Something Went Wrong') {
          ErrorToast({ text: error.response.data.data });
        } else {
          ErrorToast({ text: error.response.data.message });
        }
      },
    }
  );
  const header = (
    <div className='z-50 flex justify-between items-center'>
      <p className='invisible'>{t('common:up')}</p>
      <p>{t('common:upload_multiple_skills')}</p>
      <svg
        width='40'
        height='40'
        viewBox='0 0 67 65'
        fill='none'
        xmlns='https://www.w3.org/2000/svg'
        className='cursor-pointer'
        onClick={() => setVisible(false)}
      >
        <path
          d='M41.3962 24.3962L24.9824 40.2906'
          stroke='#EB249E'
          strokeWidth='5.70168'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M24.9824 24.3962L41.3962 40.2906'
          stroke='#EB249E'
          strokeWidth='5.70168'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );

  return (
    <div>
      <Dialog
        header={header}
        visible={isVisible}
        closable={false}
        onHide={() => setVisible(false)}
        className='w-full max-w-xl rounded-lg mx-4 md:mx-0'
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        position='center'
        resizable={false}
      >
        <div className='flex flex-col w-full items-center justify-center'>
          <div className='flex flex-col w-9/12 mt-4'>
            <div
              {...getRootProps()}
              className='w-full flex text-grey-3-900 flex-col items-center justify-end h-48 rounded border-opacity-50 border-dashed border-2 border-purple'
            >
              <input {...getInputProps()} />
              <div className='flex items-center justify-center'>
                <UploadIcon />
              </div>
              <div className='flex items-center mt-4 mb-8 justify-center'>
                {fileName
                  ? fileName
                  : 'Drag and drop or browse to choose a file'}
              </div>
            </div>
            <div className='flex w-full my-2'>{fileRejectionItems}</div>
            <div className='flex w-full my-2 text-sm text-red-600'>
              {noFileError}
            </div>
          </div>

          <div className='flex w-full mt-6'>
            <a
              href='https://stgaptahr.blob.core.windows.net/devaptahr/Skilltraining_template.xlsx'
              download
            >
              <div className=''>
                <DownloadIlls
                  className={`w-20 ${
                    currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                  }`}
                />
                <p className='text-xs text-Neutrals-gray pt-2 whitespace-nowrap'>
                  {t('common:download_template')}
                </p>
              </div>
            </a>
            <div
              className={`w-full flex justify-center ${
                currentLanguageCode === 'ar' ? 'md:pl-24' : 'md:pr-24'
              }`}
            >
              <button onClick={() => onBulkUpload()} className='w-32'>
                <PrimaryButton isLoading={bulkUploadMutation.isLoading}>
                  <p className='text-sm leading-5'>{t('common:upload_file')}</p>
                </PrimaryButton>
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default BulkUpload;
