import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { AuthContext } from 'contexts/authContext';

import Lottie from 'react-lottie-player';

import loaderLottie from 'assets/lottiefiles/loder_lottie2.json';

const CheckAuthPage = () => {
  const { authState } = useContext(AuthContext);
  const history = useHistory();
  useEffectOnce(() => {
    if (authState.isAuthenticated) {
      history.replace('/dashboard');
    } else {
      history.replace('/auth/login');
    }
  });
  return (
    <div className='flex justify-center items-center relative h-screen w-screen'>
      <Lottie
        animationData={loaderLottie}
        background='transparent'
        speed='1'
        style={{ width: '70px', height: '70px' }}
        loop
        play
      />
    </div>
  );
};

export default CheckAuthPage;
