import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import PrimaryButton from 'components/Button/primaryButton';

import { useTranslation } from 'react-i18next';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';
import { SuccessToast, ErrorToast } from 'services/toasterService';

function AddSkillModal({ isVisible, setVisible }) {
  const { t } = useTranslation(['common']);
  const defaultValues = {
    skill_word: '',
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  //   TODO: Change the api

  // eslint-disable-next-line no-unused-vars
  const bulkUploadMutation = useMutation(
    async (data) => {
      const res = await makeAPIRequest.post(apiEndPoints.BULK_UPLOAD, data);
      return res.data;
    },
    {
      onSuccess: (data) => {
        setVisible(false);
        SuccessToast({ text: data.message });
        reset({});
      },
      onError: (error) => {
        if (error.response.data.message === 'Something Went Wrong') {
          ErrorToast({ text: error.response.data.data });
        } else {
          ErrorToast({ text: error.response.data.message });
        }
      },
    }
  );

  const onSubmit = (data) => {
    // console.log(data);
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className='p-error'>{errors[name].message}</small>
    );
  };

  const header = (
    <div className='z-50 flex justify-between items-center'>
      <p className='invisible'>{t('common:add')}</p>

      <p>{t('common:add_a_skill')}</p>

      <svg
        width='40'
        height='40'
        viewBox='0 0 67 65'
        fill='none'
        xmlns='https://www.w3.org/2000/svg'
        className='cursor-pointer'
        onClick={() => {
          setVisible(false);
          reset({});
        }}
      >
        <path
          d='M41.3962 24.3962L24.9824 40.2906'
          stroke='#FF009C'
          strokeWidth='5.70168'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M24.9824 24.3962L41.3962 40.2906'
          stroke='#FF009C'
          strokeWidth='5.70168'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );

  return (
    <div>
      <Dialog
        header={header}
        visible={isVisible}
        closable={false}
        onHide={() => {
          setVisible(false);
          reset({});
        }}
        className='w-full max-w-xl rounded-lg mx-4 md:mx-0 h-45vh'
        modal
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        position='center'
        resizable={false}
      >
        <div className='flex flex-col w-full items-center justify-center'>
          <div className='w-9/12 mt-4'>
            <form onSubmit={handleSubmit(onSubmit)} id='add-skill-word'>
              <div className='w-full my-8'>
                <span className='p-float-label'>
                  <Controller
                    name='skill_word'
                    control={control}
                    rules={{
                      required: 'This is a required field',
                    }}
                    render={({ field }) => (
                      <InputText id={field.name} {...field} />
                    )}
                  />
                  <label htmlFor='skill_word'>{t('common:skill_name')} *</label>
                </span>
                {getFormErrorMessage('skill_word')}
              </div>
            </form>
          </div>

          <div className='w-full flex justify-center mt-4'>
            <button type='submit' form='add-skill-word' className='w-32'>
              <PrimaryButton>
                <p className='text-sm leading-5'>{t('common:add')}</p>
              </PrimaryButton>
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default AddSkillModal;
