import React, { createContext, useEffect, useReducer } from 'react';
export const AuthContext = createContext();

let initialState = {
  isAuthenticated: JSON.parse(localStorage.getItem('token')) ? true : false,
  token: JSON.parse(localStorage.getItem('token')) || null,

  Username: JSON.parse(localStorage.getItem('Username')) || null,
  User_initials: JSON.parse(localStorage.getItem('User_initials')) || null,
  profilePicture: JSON.parse(localStorage.getItem('profilePicture')) || null,
  user_id: JSON.parse(localStorage.getItem('user_id')) || null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem(
        'token',
        JSON.stringify(action.payload.token ?? false)
      );

      localStorage.setItem(
        'user_id',
        JSON.stringify(action.payload.user_id ?? false)
      );
      localStorage.setItem(
        'Username',
        JSON.stringify(action.payload.Username ?? false)
      );
      localStorage.setItem(
        'profilePicture',
        JSON.stringify(action.payload.profilePicture ?? '')
      );
      localStorage.setItem(
        'User_initials',
        JSON.stringify(action.payload.user_initials || '')
      );
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token ?? null,
        Username: action.payload.Username ?? null,
        profilePicture: action.payload.profilePicture ?? null,
        user_id: action.payload.user_id ?? null,
        User_initials:
          action.payload.user_initials === undefined
            ? ''
            : action.payload.user_initials,
      };

    case 'UPDATEPROFILEIMAGE':
      localStorage.setItem('profilePicture', JSON.stringify(action.payload));
      return {
        ...state,
        profilePicture: action.payload,
      };

    case 'LOGOUT':
      localStorage.clear();

      return {
        ...state,
        isAuthenticated: false,
        token: null,

        Username: null,
        profilePicture: null,
        user_id: null,
        User_initials: null,
      };
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [authState, authDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    initialState = { ...initialState };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authState,
        authDispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
